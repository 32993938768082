import React from "react";
import styles from "./How.module.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function How() {
  return (
   <div className={styles.Row}>
      <h1 className={styles.Hoofding}>
        Hoe werkt het?
      </h1>
      <div className={styles.Columns}>
        <div className={styles.Div1}>
          <FontAwesomeIcon icon={faSearch} className={styles.IconGrote}/>
        </div>
        <div className={styles.Div2}>
          <span className={styles.Text}>
            1. Zoek jouw favoriete idool
          </span>
        </div>
      </div>
      <div className={styles.Columns}>
        <div className={styles.Div1}>
          <FontAwesomeIcon icon={faClipboardList} className={styles.IconGrote}/>
        </div>
        <div className={styles.Div2}>
          <span className={styles.Text}>
            2. Geef instructies voor jouw gewenste video
          </span>
        </div>
      </div>
      <div className={styles.Columns}>
        <div className={styles.Div1}>
        <FontAwesomeIcon icon={faGift} className={styles.IconGrote}/>
        </div>
        <div className={styles.Div2}>
          <span className={styles.Text}>
            3. Maak iemand gelukkig!
          </span>
        </div>
      </div>
   </div>
  );
}

export default How;