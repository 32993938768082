import React from "react";
import { Layout } from "antd";
import styles from "./SecretPage.module.css";
import TopNavigationLandingPage from "../../Components/TopNavigationLandingPage/TopNavigationLandingPage";
import TopBlockSecretPage from "../../Components/TopBlockSecretPage/TopBlockSecretPage";
import MiddleBlockSecretPage from "../../Components/MiddleBlockSecretPage/MiddleBlockSecretPage";
import BottomBlockSecretPage from "../../Components/BottomBlockSecretPage/BottomBlockSecretPage";
import HelpSecretPage from "../../Components/HelpSecretPage/HelpSecretPage";
import AppLinkSecretPage from "../../Components/AppLinkSecretPage/AppLinkSecretPage";
import Footer from "../../Components/Footer/Footer";


function SecretPage() {

  return (
    <Layout>
      <TopNavigationLandingPage/>
      {/* <section className={styles.middle}>
        <div className={styles.Image}>
          <button onClick={onFinish} className={styles.Button}>
            <img src={pic} alt="pic" style={{ width: "50%", height: undefined }} />
          </button>
        <br/>
        <Form
      className={styles.OrderForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
    >
       <Form.Item name="Email" >            
        <Input 
          className={styles.Input}
        />
        </Form.Item>
        </Form>
        </div>
      </section> */}
      <TopBlockSecretPage className={styles.middle}/>
      <MiddleBlockSecretPage className={styles.middle}/>
      <AppLinkSecretPage className={styles.middle} />
      <BottomBlockSecretPage className={styles.middle}/>
      <HelpSecretPage className={styles.middle} />
      <Footer />
    </Layout>
  );
}

export default SecretPage;