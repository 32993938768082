import React from "react";
import styles from "./Checkoutmandje.module.css";
import { Trans } from "react-i18next";

function Checkoutmandje(props) {

  return (
    <div>
       <div className={styles.TitleDiv}>
          <br/>
            <span className={styles.PaymentTitle}>
               <Trans>Payment.Title</Trans>
            </span>
          <br/>
          <br/>
            <span className={styles.PaymentSubTitle}>
               <Trans>Payment.SubTitle1</Trans>
            </span>
          <br/>
       </div>
           <div className={styles.OrderInfo}>
              <div className={styles.List}>
                 <div>
                    <img className={styles.Image} src={props.Pic} alt="Star"/>
                 </div>
                 <div className={styles.Column}>
                    <span className={styles.ListItemNaam}>
                        {props.StarName}
                    </span>
                    <br/>
                    <br/>
                    {props.From === undefined? (
                     <span className={styles.ListItem}>
                     {props.From}
                     </span>
                      ) : (
                     <span className={styles.ListItem}>
                        <Trans>OrderForm.From</Trans> {props.From}
                    </span>
                        )}
                    <br/>
                    <span className={styles.ListItem}>
                        <Trans>OrderForm.For</Trans> {props.For}{props.Me}
                    </span>
                    <br/>
                    <span className={styles.ListItem}>
                        <Trans>OrderForm.Occasion</Trans> {props.Occasion}
                    </span>
                    <br/>
                    <br/>
                 </div>
                 <span className={styles.ListItemOccasion}>
                    {props.Instruction}
                 </span>
                 <br/>
                 <br/>
                 <div className={styles.Rows}>
                    <div className={(styles.Columns)}>
                      <span className={styles.ListItemTotal}>
                        <Trans>Payment.Total</Trans>
                      </span>
                    </div>
                    <div className={(styles.Columns)}>
                      <span className={styles.ListItemPrice}>
                        € {props.Price}
                      </span>
                   </div>
                 </div>
              </div>
           </div>
    </div>
  );
}

export default Checkoutmandje;
