import React, { useState, useEffect } from "react";
import styles from "./CardRow.module.css";
import Card from "../Card/Card";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";

function CardRow(props) {
  const [isLoading, setLoading] = useState(true);
  const [people, setPeople] = useState([]);

  const apiCall = async()=>{
    axios.get('https://www.vidstar.be/api/celebrities?_where[IsVerified]=true')
    .then((res)=>{
      res.data.forEach(element => {
        const Url = element.Alias.replace(" ", "_")   
        people.push({
          ID: element._id,
          Pic: element.ProfilePicture.url.toString(),
          Name: element.Alias,
          Profession: element.ShortDescription,
          Alias: element.Alias,
          Price: "€ "+element.Price,
          Url: Url
        });
      });
    })
    .then(()=>{
      setLoading(false) 
    }).catch(() => {
      console.log("Oops, request failed!")
    })
    .catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    apiCall();
  }, [])
    
    if (isLoading) {
      return (
        <div className={styles.CardRow}>
          <div className={styles.Top}>
            <p className={styles.Title}>
              {props.Title}isLoading
            </p>
            <p className={styles.More}>
              <Link to="/star">
                <Trans>CardRow.More</Trans>
              </Link>
            </p>
          </div>
          <div className={styles.Cards}>  
          </div>
        </div>
      )
    }

  return (
    <div className={styles.CardRow}>
      <div className={styles.Top}>
        <p className={styles.Title}>{props.Title}</p>
        <p className={styles.More}>
        <Link to="/Star/:star">
          <Trans>CardRow.More</Trans>
        </Link>
        </p>
      </div>
      <div className={styles.Cards}>
        {people.map((person) => (
          <div className={styles.Card}>
            <Link to={{pathname: `/Star/${person.Url}`, state:`${person.Alias}`}}>
              <Card
                key={person.ID}
                Image={person.Pic}
                Name={person.Name}
                Profession={person.Profession}
                Price={person.Price}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardRow;
