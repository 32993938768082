import React from "react";
import { Layout } from "antd";
import styles from "./EnrollPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Enroll from "../../Components/Enroll/Enroll";

function EnrollPage() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Enroll/>
      </section>
      <Footer />
    </Layout>
  );
}

export default EnrollPage;