import React from "react";
import { Carousel } from "antd";
import car from "./car.jpg";

const Caroussel = () => (
  <Carousel autoplay>
    <div>
      <img src={car} alt="car" style={{ width: "100%", height: undefined }} />
    </div>
  </Carousel>
);

export default Caroussel;
