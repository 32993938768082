import React,{useState} from "react";
import styles from "./Help.module.css";
import { Trans } from "react-i18next";
import YellowButton from "../YellowButton/YellowButton";
import { Input, Form} from "antd";
import axios from "axios";
const { TextArea } = Input;

function Help() {
  const [disable, setDisable] = useState (false);
  const [completed, setCompleted] = useState ('');
  const [form] = Form.useForm();

  const onFinishFailed = () => {
  };

  const onFinish = async(values)=>{
    // setDisable(true)
//     axios.post("https://www.vidstar.be/api/Support", { 
//       'Question':values.Question,
//       'Name':values.Name,
//       'Email':values.Email,
//     })
//    .then(()=>{
//       setDisable(false)
      form.resetFields();
      setCompleted('Vraag toegekomen, we nemen zo snel mogelijk contact op!')    
    // }).catch(() => {
    //   setDisable(false)
    //   setCompleted('Verbinding met de server mislukt!')
    // });
  }

  return (
    <div className={(styles.Columns, styles.InfoText)}>
        <span className={styles.Title}>
            Hulp Nodig?
        </span>
        <br />
        <span className={styles.SubText}>
            Stel hier je vraag en we helpen je zo snel mogelijk!
        </span>
        <br />
        <span className={styles.Completed}>
            {completed}
        </span>
        <br />
        <Form
            className={styles.OrderForm}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            form={form}
        >
            <Form.Item 
                name="Question" 
                rules={[
                    {
                    required: true,
                    message: 'Vraag is vereist',
                    },
                ]}
            >
                <div>
                <TextArea 
                    placeholder={'Vraag?'}
                    minLength={10} 
                    rows={6} 
                    maxLength={500} 
                    className={styles.Input}
                    />
                </div>
            </Form.Item>
            <Form.Item 
                name="Name" 
                rules={[
                    {
                    required: true,
                    message: 'Naam is vereist',
                    },
                ]}
            >
                <div>
                    <Input 
                    className={styles.Input} 
                    placeholder={'Naam?'}
                    />
                </div>
            </Form.Item>
            <Form.Item 
                name="Email" 
                rules={[
                    {
                    type: 'email',
                    message: 'Geen geldig Emailadres',
                    },
                    {
                    required: true,
                    message: 'Emailadres is vereist',
                    },
                ]}
             >
                <div>
                    <Input 
                    className={styles.Input} 
                    placeholder={'Emailadres?'}
                    />
                </div>
            </Form.Item>
            <Form.Item>
                <div className={styles.YellowButton}>
                    <YellowButton
                    className={styles.YellowButton}
                    Text={<Trans>Help.ButtonText</Trans>}
                    disabled ={disable}
                    ></YellowButton>
                </div>
            </Form.Item>
        </Form>
    </div>
  )
}

export default Help;