import React from "react";
import styles from "./MiddleBlockSecretPage.module.css";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import ExclamationMark from "./Exclamation.png";
import Heart from "./heart.png";
import Like from "./like.png";



const MiddleBlockSecretPage = () => (
  <div className={styles.Background}>
    <div className={styles.Column1}>
      <div className={styles.RowIcon}>
        <img src={ExclamationMark} alt='ExclamationMark' className={styles.IconImage} />
      </div>
      <div className={styles.RowTitle}>
        <span className={styles.TitleText}>
          Verbaas je fans
        </span>
      </div>
      <div className={styles.RowText}>
        <span className={styles.NormalText}>
          Vidstar maakt verrassende connecties mogelijk. Maak deel uit van de belangrijkste momenten van je fans: verjaardagen, roasts en meer...
        </span>
      </div>
    </div>
    <div className={styles.Column2}>
      <div className={styles.RowIcon}>
        <img src={Heart} alt='Heart' className={styles.IconImage} />
      </div>
      <div className={styles.RowTitle}>
        <span className={styles.TitleText}>
          Steun jouw goed doel
        </span>
      </div>
      <div className={styles.RowText}>
        <span className={styles.NormalText}>
          Hou er zelf iets aan over, schenk je inkomsten aan een goed doel of doe het gewoon helemaal gratis!
        </span>
      </div>
    </div>
    <div className={styles.Column3}>
      <div className={styles.RowIcon}>
        <img src={Like} alt='Like' className={styles.IconImage} />
      </div>
      <div className={styles.RowTitle}>
        <span className={styles.TitleText}>
          Jij kiest wanneer
        </span>
      </div>
      <div className={styles.RowText}>
        <span className={styles.NormalText}>
          Aan het wachten op de trein? Enkele minuten vrij tussendoor? Je kan je video's altijd en overal opnemen.
        </span>
      </div>
    </div>
  </div>
);

export default MiddleBlockSecretPage;
