import React from "react";
import styles from "./Faq.module.css";

function Faq(props) {
  return (
    <div className={(styles.Columns, styles.InfoText)}>
      <span className={styles.Title}>Veel Gestelde Vragen</span>
      <br />
      <br />
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Waarom zou ik lid worden van Vidstar?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Waarom niet? Je kan in jouw vrije tijd geld verdienen of geld inzamelen voor een goed doel. Je biedt jouw fans gepersonaliseerde, onvergetelijke momenten aan die hen inspireren en waarbij ze zich met jouw verbonden voelen!
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Hoe maak ik een Vidstar account aan?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Je kan de Vidstar App downloaden (beschikbaar op 
      </span>
      <span className={styles.InfoText}> 
      <a className={styles.TextLink} href="https://apps.apple.com/be/app/vidstar/id1544865377?l=nl" target="_blank"> iPhone </a>
      </span> 
      <span className={styles.InfoText}>
       en
      </span>
      <span className={styles.InfoText}> 
      <a className={styles.TextLink} href="https://play.google.com/store/apps/details?id=com.Vidstar.Vidstar" target="_blank"> Android </a>
      </span> 
      <span className={styles.InfoText}>
      ). Met de invitecode '
      </span> 
      <span className={styles.InfoTextBold}>
      123456
      </span> 
      <span className={styles.InfoText}>
      ' kan je een account aanmaken. Hulp nodig? Vul dan 
      </span> 
      <a className={styles.TextLink} href="/enroll"> hier </a>
      <span className={styles.InfoText}>
      je gegevens in en we nemen contact op!
      </span> 
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Waarom moet ik mijn contactgegevens delen met Vidstar?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Al onze talenten hebben toegang tot een talent relations partner bij Vidstar. Wij moeten er zeker van zijn dat we je kunnen bereiken, zodat je de best mogelijke ervaring krijgt! Hoewel je jouw contactgegevens met ons deelt, delen wij deze niet met jouw fans. Zie ons 
      </span>
      <a className={styles.TextLink} href="/privacy" target="_blank"> Privacybeleid </a>
      <span className={styles.InfoText}>
      voor meer informatie.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Hoe wordt de prijs van een talent voor een Vidstar-video bepaald?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Elk talent bepaalt zijn eigen prijs en kan die op elk moment wijzigen! Alle talenten hebben exact dezelfde inkomstenverdeling - 80% voor het talent, 20% voor ons. Om eerlijk te zijn voor al onze talenten, is dit een verdeling waar niet over onderhandeld kan worden.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Hoe wordt ik betaald?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Zodra je talentprofiel is goedgekeurd, kun je in-app of online bankieren en worden je verdiensten elke maand op je rekening gestort, tenzij anders aangegeven.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Hoeveel tijd kost het om een Vidstar-video op te nemen?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Dat bepaal je helemaal zelf! Gemiddeld duurt het ongeveer 30 seconden, maar er is geen tijdslimiet!
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Kan ik kiezen welke Vidstar video's ik opneem?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Ja - je kan elk verzoek accepteren of afwijzen. Als je vragen hebt over een verzoek, kan je contact opnemen met jouw talent relations partner of help@vidstar.be
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V:Ik ken iemand die geïnteresserd is, kan ik hem/haar doorverwijzen?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Ja - we breiden de Vidstar familie graag uit! We hebben een doorverwijzingsprogramma voor talenten waarbij je 5% van de verdiensten (uit het Vidstar-aandeel) in het eerste jaar kunt ontvangen van talent dat je doorverwijst!
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Ik ben enkele weken niet beschikbaar, kan ik mijn profiel deactiveren?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: Je kan altijd een pauze inlassen d.m.v. jouw profiel te deactiveren via de app of contact op te nemen met je talent relations partner.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>V: Waar kan ik Vidstar's algemene voorwaarden vinden?</span>
      <br />
      <br />
      <span className={styles.InfoText}>A: je kan onze algemene voorwaarden
      </span>
      <a className={styles.TextLink} href="/terms"> hier </a>
      <span className={styles.InfoText}>
      vinden.
      </span>
      <br />
    </div>
  );
}

export default Faq;
