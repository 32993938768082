import React from "react";
import styles from "./CheckoutInfo.module.css";
import { Trans } from "react-i18next";

function CheckoutInfo(props) {
  return (
    <div>
       <div className={styles.TitleDiv}>
          <span className={styles.PaymentSubTitle}>
             <Trans>Payment.SubTitle2</Trans>
          </span>
          <br/>
       </div>
       <div className={styles.OrderInfo}>
          <div className={styles.List}>
             <div className={styles.Column}>
               <span className={styles.ListDetailTitle}>
                 <Trans>OrderForm.Email</Trans>
               </span>
               <br/>
               <span className={styles.ListDetail}>
                 {props.Email}
               </span>
               <br/>
               <span className={styles.ListDetailTitle}>
                 <Trans>OrderForm.Gsm</Trans>
               </span>
               <br/>
               <span className={styles.ListDetail}>
                 {props.Gsm}
               </span>
               <br/>
             </div>
          </div>
       </div>
    </div>
  );
}

export default CheckoutInfo;
