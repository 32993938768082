import React from "react";
import styles from "./Card.module.css"
import hart from "./Hart.png";

function Card(props) {
  return (
    <div className={styles.Card}>
      <div className={styles.ImageWrapper}>
        <img src={props.Image} alt={props.Name} className={styles.Image} />
        <img src={hart} alt='hart' className={styles.hart} />
        <span className={styles.Price}>{props.Price}</span>
      </div>
      <div className={styles.Box}>
        <div className={styles.Name}>{props.Name}</div>
        <div className={styles.Info}>
          {props.Profession}
        </div>
      </div>
    </div>
  );
}

export default Card;
