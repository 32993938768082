import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "TopNavigation.Menu": "I'm a talent",
          "CardRow.More": "More",
          "Footer.Enroll": "Enroll as profile",
          "WhatIs.Icon1": "send your request",
          "WhatIs.Icon2": "receive your personalized message",
          "WhatIs.Icon3": "Make someone happy!",
          "Footer.Mails": "Subscribe to our newsletter",
          "Footer.FAQ": "Frequently asked questions",
          "Footer.Privacy": "Privacy",
          "Footer.Terms": "Terms",
          "Footer.Help": "Help",
          "Footer.Copyright": "Copyright © 2020 vidstar.be",
          "Info.Button": "Book for ",
          "OrderInfo.Item1": "Has 7 days Time to complete your request",
          "OrderInfo.SubItem1": "Although its usually quicker",
          "OrderInfo.Item2":"Easy to use",
          "OrderInfo.SubItem2":"You will receive your video by SMS and E-mail when your request is complete",
          "OrderInfo.Item3": "If for any reason your request cannot be completed",
          "OrderInfo.SubItem3":" you will receive a full refund within 10 workdays",
          "OrderForm.VideoFor": "This video is for:",
          "OrderForm.VideoFor.Me": "Myself",
          "OrderForm.VideoFor.Someone": "Someone else",
          "OrderForm.Me": "My name is:",
          "OrderForm.From": "From:",
          "OrderForm.For": "For:",
          "OrderForm.Occasion": "Occasion:",
          "OrderForm.Occasion.Birthday": "Birthday",
          "OrderForm.Occasion.Invite": "Invite",
          "OrderForm.Occasion.Christmas": "Christmas",
          "OrderForm.Occasion.Joke": "Joke",
          "OrderForm.Occasion.Roast": "Roast",
          "OrderForm.Occasion.Peptalk": "Pep talk",
          "OrderForm.Occasion.Other": "Other",
          "OrderForm.Instructions": "Instructions for",
          "OrderForm.SendTo": "Send this Vidstar to:",
          "OrderForm.Email": "E-mail:",
          "OrderForm.Gsm": "Phone:",
          "OrderForm.Checkbox": "Cannot be used on Vidstar:",
          "OrderForm.Button": "Continue to payment",
          "OrderForm.AcceptTerms1": "By continuing you agree to our ",
          "OrderForm.AcceptTerms2": "Terms and conditions",
          "Payment.Title": "Complete your purchase",
          "Payment.SubTitle1": "your basket",
          "Payment.SubTitle2": "Jouw details",
          "Payment.SubTitle3": "Payment",
          "Payment.Total": "Total",
          "Payment.Price": "25",
          "Payment.Bancontact": "Bancontact",
          "Payment.CreditCard": "Creditcard",
          "Payment.ButtonText": "Pay",
          "Help.ButtonText": "Send message",
          "Enroll.ButtonText": "Enroll",
        },
      },
      nl: {
        translations: {
          "TopNavigation.Menu": "Ik ben een talent",
          "CardRow.More": "Meer",
          "WhatIs.Icon1": "zend een verzoek",
          "WhatIs.Icon2": "Ontvang je gepersonaliseerde video",
          "WhatIs.Icon3": "Maak iemand gelukkig!",
          "Footer.Enroll": "Meld je aan als Talent",
          "Footer.Mails": "Meld je aan voor onze nieuwsbrief",
          "Footer.FAQ": "Veelgestelde vragen",
          "Footer.Privacy": "Privacybeleid",
          "Footer.Terms": "Algemene voorwaarden",
          "Footer.Help": "Help",
          "Footer.Copyright": "Copyright © 2020 vidstar.be",
          "Info.Button": " Boeken voor ",
          "OrderInfo.Item1":
          "heeft 7 dagen tijd om te antwoorden",
          "OrderInfo.SubItem1": " - 90% antwoordt veel sneller",
          "OrderInfo.Item2":"Gemakkelijk te gebruiken",
          "OrderInfo.SubItem2":" - Je ontvangt jouw video via Email en/of SMS",
          "OrderInfo.Item3A": "Als ",
          "OrderInfo.Item3B": " Niet antwoordt",
          "OrderInfo.SubItem3":" - krijg je ALTIJD jouw geld terug",
          "OrderForm.VideoFor": "Deze video is voor:",
          "OrderForm.VideoFor.Me": "Mezelf",
          "OrderForm.VideoFor.Someone": "Iemand anders",
          "OrderForm.Me": "Mijn naam is:",
          "OrderForm.From": "Van:",
          "OrderForm.For": "Voor:",
          "OrderForm.Occasion": "Gelegenheid:",
          "OrderForm.Occasion.Birthday": "Verjaardag",
          "OrderForm.Occasion.Invite": "Uitnodiging",
          "OrderForm.Occasion.Christmas": "Kerstmis",
          "OrderForm.Occasion.Joke": "Grapje",
          "OrderForm.Occasion.Roast": "Roast",
          "OrderForm.Occasion.Peptalk": "Pep talk",
          "OrderForm.Occasion.Other": "Andere",
          "OrderForm.Instructions": "Instructies voor",
          "OrderForm.SendTo": "Zend de Vidstar naar:",
          "OrderForm.Email": "E-mail:",
          "OrderForm.Gsm": "Gsm:",
          "OrderForm.Checkbox": "Mag niet op Vidstar gebruikt worden",
          "OrderForm.Button": "Verder naar betalen",
          "OrderForm.AcceptTerms1": "Door verder te gaan ga je akkoord met onze ",
          "OrderForm.AcceptTerms2": "Algemene Voorwaarden",
          "Payment.Title": "Vervolledig je aankoop",
          "Payment.SubTitle1": "Jouw winkelmandje ",
          "Payment.SubTitle2": "Jouw gegevens",
          "Payment.SubTitle3": "Betalingswijze",
          "Payment.Total": "Totaal",
          "Payment.Price": "25",
          "Payment.Bancontact": "Bancontact",
          "Payment.CreditCard": "Creditcard",
          "Payment.ButtonText": "Verder naar betalen",
          "Help.ButtonText": "Verstuur je bericht",
          "Enroll.ButtonText": "Aanmelden",
        },
      },
    },
    fallbackLng: "nl",
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });
export default i18n;
