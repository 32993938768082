import React from "react";
import styles from "./Info.module.css";
import YellowButton from "../YellowButton/YellowButton";
import { Link } from "react-router-dom";
import InfoVideocard from "../InfoVideocard/InfoVideocard";
import InfoGoedDoel from "../InfoGoedDoel/InfoGoedDoel";

function Info(props) {
  return (
     <div className={styles.Rows}>
        <div className={styles.Columns}>
          <InfoVideocard  
            Video={props.StarVideo}
            Poster={props.StarPoster} 
          />
        </div>
        <div className={styles.Text}>
          <div classname={styles.InfoText}>
          <span className={styles.StarName}>
              {props.StarName}
          </span>
          <br />
          <span className={styles.StarShow}>
              {props.StarShow}
          </span>
          <br />
          <p className={styles.StarInfo}>
              {props.StarInfo} 
          </p>
        </div>
        <div classname={styles.Button}>
          <Link to={{pathname: `/order/${props.StarUrl}`, state:`${props.StarName}`}}>
                <div className={styles.YellowButton}>
                  <YellowButton 
                    Text={props.StarPrice}
                  />
                </div>
          </Link>
        </div>
        </div>
        <div className={styles.ColumnsGoedDoel}>
          <InfoGoedDoel
          StarName={props.StarName}
          >       
          </InfoGoedDoel>
        </div>
     </div>
  );
}

export default Info;
