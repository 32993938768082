import React from "react";
import { Layout } from "antd";
import styles from "./PaidPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Paid from "../../Components/Paid/Paid";


function PaidPage() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
         <Paid/>
      </section>
      <Footer />
    </Layout>
  );
}

export default PaidPage;