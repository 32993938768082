import React from "react";
import styles from "./HelpSecretPage.module.css";
import Question from "./Question.png";


function HelpSecretPage() {
 

  return (
    <div className={styles.Background}>
          <div className={styles.BigBlock}>
            <div className={styles.Block1}>
              <div className={styles.LeftRow1}>
                <span className={styles.TitleText}>
                   Heb je nog vragen?
                </span>
              </div>
              <div className={styles.LeftRow2}>
                <a href="/FAQ" className={styles.Button1}>
                  Veelgestelde vragen
                </a>
                <a href="mailto:nicolas@vidstar.be" className={styles.Button2}>
                  Contact
                </a>
              </div>
            </div>
            <div className={styles.Block2}>
            <img src={Question} alt='question' className={styles.Image} />
            </div>
          </div>
    </div>
  )
}

export default HelpSecretPage;