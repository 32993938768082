import React from "react";
import styles from "./IntroVideoAppVideo.module.css";
import vid from "./AppIntroVideo.MP4";

function IntroVideoAppVideo() {
  return (
    <div className={styles.Card}>
      <video
        className={styles.Video}
        preload={'auto'}
        src={vid}
        type="video/mp4"
        autoPlay
        loop
        controls
      />
    </div>
  );
}

export default IntroVideoAppVideo;
