import React from "react";
import styles from "./OrderPicture.module.css";

function OrderPicture(props) {
  return (
    <div className={styles.Columns}>
      <img className={styles.Image} src={props.Pic} alt="Star"></img>
      <span className={styles.Rating}>
        <span className={styles.StarName}>{props.StarName}</span>
        <p className={styles.StarInfo}>{props.StarInfo}</p>
      </span>
    </div>
  );
}

export default OrderPicture;
