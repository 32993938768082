import React from "react";
import styles from "./Paid.module.css";
import { Link } from "react-router-dom";

function Paid() {
  return (
    <div className={(styles.Columns, styles.InfoText)}>
    <span className={styles.Title}>
      Joepie!
    </span>
    <br />
    <span className={styles.DatumText}>
      Je verzoek is verzonden.
    </span>
    <br />
    <br />
    <img src={require('./giphy.gif')} alt="loading..." className={styles.Image}/>
    <br />
    <br />
    <span className={styles.Subtitle}>
      Nog Wat korte informatie:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      - Binnen de 7 dagen kan je jouw video verwachten!
    </span>
    <br />
    <span className={styles.InfoText}>
      - Je krijgt je videolink via E-mail en/of SMS.
    </span>
    <br />
    <span className={styles.InfoText}>
      - Verdere informatie kan je raadplegen via je bevestigingsmail
    </span>
    <br />
    <br />
    <Link to="/">
    <span className={styles.Link}>
      Verder
    </span>
    </Link>
    </div>  
  );
}

export default Paid;