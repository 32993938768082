import { Store } from "pullstate";

export const UserStore = new Store({
  _id: '',
  Email:'',
  Gsm:'',
  Instruction:'',
  Me:'',
  For:'',
  From:'',
  Occasion:'',
  Private:'',
});