import React from "react";
import { Layout } from "antd";
import styles from "./Payment.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Checkout from "../../Components/Checkout/Checkout";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";


function Payment(props) {
  const location = useLocation();
  if (location.state === undefined) {
    return <Redirect to="/" />;
  } else {
    return  <Layout>
    <TopNavigation />
    <section className={styles.middle}>
      <Checkout 
        Pic={location.state.pic}
        StarName={location.state.name}
        From={location.state.values.From}
        For={location.state.values.For}
        Me={location.state.values.Me}
        Occasion={location.state.values.Occasion}
        Instruction={location.state.values.Instruction}
        Price={location.state.price}
        Email={location.state.values.Email}
        Gsm={location.state.values.Gsm}
        Id={location.state.values.Id}
      />
    </section>
    <Footer />
  </Layout>;
  }
}

export default Payment;
