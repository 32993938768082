import React, {useState} from "react";
import styles from "./VideoDone.module.css";
import YellowButton from "../YellowButton/YellowButton";
import { Link } from "react-router-dom";
import InfoVideocard from "../VideoCardDone/VideoCardDone";
import { StarFilled, DownloadOutlined, FacebookOutlined, WhatsAppOutlined, TwitterOutlined, LinkOutlined } from "@ant-design/icons";
import { Input } from "antd";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import FileSaver from 'file-saver';
const { TextArea } = Input;

function VideoDone(props) {
  const [sterEen, SetSterEen] = useState('black');
  const [sterTwee, SetSterTwee] = useState('black');
  const [sterDrie, SetSterDrie] = useState('black');
  const [sterVier, SetSterVier] = useState('black');
  const [sterVijf, SetSterVijf] = useState('black');
  const [sterScore, SetSterScore] = useState(0);
  const [review, setReview] = useState('');

  const downloadUrl= props.src
  const videoUrl= props.url

  const OnChangeReview = (text) =>{
    setReview(text);
  };

  const SaveFiles = () =>{
    FileSaver.saveAs(downloadUrl, "video.mp4");
  };

  const Starchanger1 = () => {
    if(sterEen === 'black'){
      SetSterEen('yellow')
      SetSterScore(1)
    }
    else if(sterTwee === 'yellow'){
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterEen('yellow')
      SetSterScore(1)
    }
    else{
      SetSterEen('black')
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterScore(0)
    }
  };

  const Starchanger2 = () => {
    if(sterTwee === 'black'){
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterScore(2)
    }
    else if(sterDrie === 'yellow'){
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterScore(2)
    }
    else {
      SetSterEen('black')
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterScore(0)
    }
  };

  const Starchanger3 = () => {
    if(sterDrie === 'black'){
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterDrie('yellow')
      SetSterScore(3)
    }
    else if(sterVier === 'yellow'){
      SetSterDrie('yellow')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterScore(3)
    }
    else {
      SetSterEen('black')
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterScore(0)
    }
  };

  const Starchanger4 = () => {
    if(sterVier === 'black'){
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterDrie('yellow')
      SetSterVier('yellow')
      SetSterScore(4)
    }
    else if(sterDrie === 'yellow'){
      SetSterDrie('yellow')
      SetSterVier('yellow')
      SetSterVijf('black')
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterScore(4)
    }
    else {
      SetSterEen('black')
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterScore(0)
    }
  };

  const Starchanger5 = () => {
    if(sterVijf === 'black'){
      SetSterEen('yellow')
      SetSterTwee('yellow')
      SetSterDrie('yellow')
      SetSterVier('yellow')
      SetSterVijf('yellow')
      SetSterScore(5)
    }
    else {
      SetSterEen('black')
      SetSterTwee('black')
      SetSterDrie('black')
      SetSterVier('black')
      SetSterVijf('black')
      SetSterScore(0)
    }
  };

  return (
     <div className={styles.Rows}>
        <div className={styles.Columns}>
          <InfoVideocard  
            Video={props.src}
            Poster={props.StarPoster} 
          />
        </div>
        <div className={styles.ColumnsReview}>    
            <br />
            <div className={(styles.Columns, styles.InfoStars)}>
              <DownloadOutlined className={styles.Icons1} style={{ fontSize: '45px', color: '#fac42b' }} type="edit" onClick={SaveFiles}/>
              <LinkOutlined className={styles.Icons} style={{ fontSize: '45px', color: '#fac42b' }} type="edit" onClick={() => {navigator.clipboard.writeText(videoUrl)}}/>  
              <FacebookShareButton 
                quote={"© Vidstar"}
                url={props.url}
              >
                <FacebookOutlined className={styles.Icons} style={{ fontSize: '45px', color: '#fac42b' }}/>
              </FacebookShareButton>
              <TwitterShareButton
                url={props.url}
              >
                <TwitterOutlined className={styles.Icons} style={{ fontSize: '45px', color: '#fac42b' }}/>
              </TwitterShareButton>
              <WhatsappShareButton
                url={props.url}
              >
                <WhatsAppOutlined className={styles.Icons} style={{ fontSize: '45px', color: '#fac42b' }} type="edit" onClick={Starchanger1}/>
              </WhatsappShareButton>
            </div>
            <span className={styles.StarShow}>
                Beoordeling:
            </span>
            <div className={(styles.Columns, styles.InfoStars)}>
              <StarFilled className={styles.Stars1} style={{ fontSize: '30px', color: sterEen }} type="edit" onClick={Starchanger1}/>
              <StarFilled className={styles.Stars} style={{ fontSize: '30px', color: sterTwee }} type="edit" onClick={Starchanger2}/>
              <StarFilled className={styles.Stars} style={{ fontSize: '30px', color: sterDrie }} type="edit" onClick={Starchanger3}/>
              <StarFilled className={styles.Stars} style={{ fontSize: '30px', color: sterVier }} type="edit" onClick={Starchanger4}/>
              <StarFilled className={styles.Stars} style={{ fontSize: '30px', color: sterVijf }} type="edit" onClick={Starchanger5}/>
            </div>
            <br />
            <div className={styles.Instruction}>
              <TextArea
                placeholder={'bv: Wow! helemaal top! Mijn broer heeft zo hard gelachen.'}
                minLength={20} 
                rows={4} 
                maxLength={200} 
                onChange={ (text)=>OnChangeReview(text)}
                defaultValue={review}
                className={styles.Input}
              />
            </div>
            <Link to={{pathname: `/order/${props.StarUrl}`, state:`${props.StarName}`}}>
                <div className={styles.YellowButton}>
                  <YellowButton 
                    Text={'verstuur'}
                  />
                </div>
            </Link>
        </div>
    </div>
  );
}

export default VideoDone;