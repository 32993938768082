import React from "react";
import { Layout } from "antd";
import styles from "./IntroVideoAppPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import IntroVideoApp from "../../Components/IntroVideoApp/IntroVideoApp";

function IntroVideoAppPage(props) {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <IntroVideoApp />
      </section>
      <Footer />
    </Layout>
  );
}

export default IntroVideoAppPage;
