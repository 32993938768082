import React from "react";
import styles from "./YellowButton.module.css";
import { Button } from "antd";

function YellowButton(props) {
  return (
    <Button className={styles.Button} htmlType="submit">
      {props.Text}
    </Button>
  );
}

export default YellowButton;
