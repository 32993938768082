import React from "react";
import { Trans } from "react-i18next";
import { Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { MailOutlined } from "@ant-design/icons";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { Button } from 'antd';
import Instagram from "./instagram.png";


const Footer = () => (
  <div className={styles.FooterMain}>
     <div className={styles.Joinen}>
        <Link to={{pathname: `/Enroll`}}>
            <Button className={styles.PressButtonText} >
              <Trans>
                  Footer.Enroll
              </Trans>
            </Button>
        </Link>
     </div>
     <div className={styles.Footer}>
        <div className={styles.Mail}>
           <p>
              <Trans>
                 Footer.Mails
              </Trans>
           </p>
           <div className={styles.Row}>
              <Input
                className={styles.InputMail}
                placeholder="E-mailadres"
                suffix={<MailOutlined className={styles.MailIcon} />}
              />
              <Button 
                className={styles.PressButton} 
                icon={<ArrowRightOutlined className={styles.ArrowButton} />}
              />
           </div>
        </div>
        <div className={styles.Empty}/>
        <div className={styles.Info}>
          <p>
            {/* <Link to="/How/">
              <Trans>
                TopNavigation.Menu
              </Trans>
            </Link> */}
            <a href="https://www.instagram.com/vidstar.be/" target="_blank">
              <img src={Instagram} alt='insta' className={styles.Image} />
            </a>
          <br />
            <Link to="/FAQ/">
              <Trans>
                Footer.FAQ
              </Trans>
            </Link>
          <br />
            <Link to="/Privacy/">
              <Trans>
                Footer.Privacy
              </Trans>
            </Link>
          <br />
            <Link to="/terms/">
              <Trans>
                 Footer.Terms
              </Trans>
            </Link>
          <br />
            <Link to="/Help/">
              <Trans>
                 Footer.Help
              </Trans>
            </Link>
          </p>
          <p>
            <Trans>
               Footer.Copyright
            </Trans>
          </p>
        </div>
     </div>
  </div>
);

export default Footer;
