import React, { useState} from "react";
import styles from "./OrderForm.module.css";
import YellowButton from "../YellowButton/YellowButton";
import { Input, Select, Form, Checkbox } from "antd";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserStore } from "../../Store/UserStore";
const { Option } = Select;
const { TextArea } = Input;

function OrderForm(props) {
  const [videoFor, setVideoFor] = useState("Me");
  const [TextAreaSize, setTextAreaSize] = useState(0);
  const history = useHistory();
  const [price, setPrice] = React.useState({...props.StarPrice});
  const [name, setName] = React.useState({...props.StarName});
  const [pic, setPic] = React.useState({...props.StarPic});

  React.useEffect(() => {
    setPrice(props.StarPrice);
    setName(props.StarName);
    setPic(props.StarPic);
  }, [props.StarPrice])

  function handleChangeVideoFor(value) {
    setVideoFor(value);
  }

  function handleChangeTextArea(v) {
    setTextAreaSize(v.target.value.length);
  }

  const onFinish = async(values)=>{
    UserStore.update(s => {
      s.Email = values.Email
      s.Gsm = values.Gsm
      s.Instruction = values.Instruction
      s.Me = values.Me
      s.For = values.For
      s.From = values.From
      s.Occasion = values.Occasion
      s.Private = values.Private
    })
    history.push("/Checkout", {values, price, name, pic});
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      className={styles.OrderForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
    >
        <p className={styles.Text}>
          <Trans>OrderForm.VideoFor</Trans>
        </p>
        <Form.Item name="VideoFor" initialValue="Me">
        <Select
          className={styles.VideoFor}
          onChange={handleChangeVideoFor}
        >
          <Option value="Me" className={styles.Option}>
            <Trans>OrderForm.VideoFor.Me</Trans>
          </Option>
          <Option value="Someone" className={styles.Option}>
            <Trans>OrderForm.VideoFor.Someone</Trans>
          </Option>
        </Select>
      </Form.Item>
      {videoFor === "Me" ? (
        <Form.Item name="For" rules={[{ required: true }]}>
          <div className={styles.Me}>
            <p className={styles.Text}>
              <Trans>OrderForm.Me</Trans>
            </p>
            <Input className={styles.Input} />
          </div>
        </Form.Item>
      ) : (
          <div className={styles.Rows}>
            <div className={(styles.Columns, styles.From)}>
              <p className={styles.Text}>
                <Trans>OrderForm.From</Trans>
              </p>
              <Form.Item name="From" rules={[{ required: true }]}>
                <Input className={styles.Input}/>
              </Form.Item>
            </div>
            <div className={(styles.Columns, styles.For)}>
              <p className={styles.Text}>
                <Trans>OrderForm.For</Trans>
              </p>
              <Form.Item name="For" rules={[{ required: true }]}>
                <Input className={styles.Input} />
              </Form.Item>
            </div>
          </div>
      )}
        <div className={styles.Occasion}>
          <p className={styles.Text}>
            <Trans>OrderForm.Occasion</Trans>
          </p>
          <Form.Item name="Occasion" initialValue="Andere" >
            <Select className={styles.OccasionSelect}>
              <Option value="Verjaardag" className={styles.Option}>
                <Trans>OrderForm.Occasion.Birthday</Trans>
              </Option>
              <Option value="Uitnodiging" className={styles.Option}>
                <Trans>OrderForm.Occasion.Invite</Trans>
              </Option>
              <Option value="Kerstmis" className={styles.Option}>
                <Trans>OrderForm.Occasion.Christmas</Trans>
              </Option>
              <Option value="Mopje" className={styles.Option}>
                <Trans>OrderForm.Occasion.Joke</Trans>
              </Option>
              <Option value="Roast" className={styles.Option}>
                <Trans>OrderForm.Occasion.Roast</Trans>
              </Option>
              <Option value="Peptalk" className={styles.Option}>
                <Trans>OrderForm.Occasion.Peptalk</Trans>
              </Option>
              <Option value="Andere" className={styles.Option}>
                <Trans>OrderForm.Occasion.Other</Trans>
              </Option>
            </Select>
          </Form.Item>
        </div>
      <Form.Item name="Instruction" rules={[{ required: true }]}>
        <div className={styles.Instruction}>
          <p className={styles.Text}>
            <Trans>OrderForm.Instructions</Trans> {props.StarName + " ("}
            {TextAreaSize + "/256):"}
            <span className={styles.TextAreaNumber}></span>
          </p>
          <TextArea 
            placeholder={'Bv: Kan je mijn broer Nicolas (Franse uitspraak) een gelukkige verjaardag wensen? Hij vind je films helemaal top!'}
            minLength={20} 
            rows={4} 
            maxLength={256} 
            onChange={handleChangeTextArea} 
            className={styles.Input}
            />
        </div>
      </Form.Item>

      <div className={styles.SendTo}>
        <p className={styles.Text}>
          <Trans>OrderForm.SendTo</Trans>
        </p>
          <div className={styles.Rows}>
              <div className={(styles.Columns, styles.Email)}>
                <p className={styles.Text}>
                  <Trans>OrderForm.Email</Trans>
                </p>
                <Form.Item name="Email" rules={[
                  {
                    type: 'email',
                    message: 'Geen geldig Emailadres',
                  },
                  {
                    required: true,
                    message: 'Emailadres is vereist',
                  },
                ]}>
                  <Input className={styles.Input} />
                </Form.Item>
              </div>
              <div className={(styles.Columns, styles.Gsm)}>
                <p className={styles.Text}>
                  <Trans>OrderForm.Gsm</Trans>
                </p>
                <Form.Item name="Gsm" >
                  <Input className={styles.Input} />
                </Form.Item>
              </div>
          </div>
      </div>
      <div className={styles.Checkboxdiv}>
        <Form.Item name="Private" valuePropName="checked">
          <Checkbox className={styles.Text}>
            <Trans className={styles.Text}>OrderForm.Checkbox</Trans>
          </Checkbox>
        </Form.Item>
      </div>
        <Form.Item>
          <div className={styles.YellowButton}>
            <YellowButton
              className={styles.YellowButton}
              Text={<Trans>OrderForm.Button</Trans>}
            ></YellowButton>
          </div>
        </Form.Item>
        <div className={(styles.Columns, styles.TextvoorwaardenDiv)}>
              <p className={styles.TextVoorwaarden}>
                <Trans>OrderForm.AcceptTerms1</Trans>
                <Link to="/Terms/" className={styles.TextVoorwaardenClickbaar}><Trans>OrderForm.AcceptTerms2</Trans></Link>
              </p>
        </div>
    </Form>
  );
}

export default OrderForm;
