import React from "react";
import { Layout } from "antd";
import styles from "./VideoPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import VideoDone from "../../Components/VideoDone/VideoDone";


function VideoPage(props) {
  const { orderId } = props.match.params;

  const downloadUrl = 'https://vidstar-file-storage.ams3.cdn.digitaloceanspaces.com/'+orderId+'.mp4'
  const videoUrl = 'https://www.vidstar.be/V/'+orderId
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <VideoDone
          src={downloadUrl}
          url={videoUrl}
        />
      </section>
      <Footer />
    </Layout>
  );
}

export default VideoPage;