import React from "react";
import styles from "./InfoVideocard.module.css";

function InfoVideocard(props) {
  return (
    <div className={styles.Card}>
      <video
        className={styles.Video}
        controls
        preload={'auto'}
        src={props.Video}
        type="video/mp4"
        autoPlay
      />
    </div>
  );
}

export default InfoVideocard;
