import React from "react";
import styles from "./InfoGoedDoel.module.css";
import { Trans } from "react-i18next";
import hart from "./Hart.png";

function InfoGoedDoel(props) {
    //Todo goede doelen hierin steken
  return (
    <div className={styles.OrderInfo}>
       <div className={styles.List}>
          <img src={hart} alt='hart' className={styles.hart} />
          <span className={styles.ListItem}>
            {props.StarName}{" "}
          </span>
          <span className={styles.ListItemGrijs}>
            steunt 
          </span>
          <img src={hart} alt='hart' className={styles.hart} />
          <br />
          <span className={styles.ListItem}>
            "Kom op tegen kanker"
          </span>
          <br />
          <br />
          <span className={styles.ListItemGrijs}>
            Info:{" "}
          </span>
          <span className={styles.ListItemLink}>
            <a className={styles.TextLink} href="https://www.komoptegenkanker.be" target="_blank"> https://www.komoptegenkanker.be </a>
          </span>
          <br />
        </div>
    </div>
  );
}

export default InfoGoedDoel;