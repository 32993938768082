import React from "react";
import styles from "./Terms.module.css";
import { Link } from "react-router-dom";

function Terms(props) {
  return (
    <div className={(styles.Columns, styles.InfoText)}>
      <span className={styles.Title}>
        Algemene Voorwaarden
      </span>
      <br />
      <span className={styles.DatumText}>
        Laatst bijgewerkt op 11 december 2020.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Deze Algemene voorwaarden overeenkomst ("Voorwaarden") regelt uw gebruik van het VidStar-platform voor gepersonaliseerde video’s en andere producten en diensten die door Vidstar (“we”, "wij", "ons" of "Vidstar") worden aangeboden via onze website (Vidstar.be), mobiele applicatie ('App') en via de services die we bieden (gezamenlijk de website, App en services die onze 'Site' worden genoemd). "U" verwijst naar u als gebruiker van onze site.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Deze voorwaarden zijn van toepassing op bezoekers en gebruikers van onze site. Elk gebruik door u van onze Site als STAR (hieronder gedefinieerd) wordt beheerst door de STAR Algemene voorwaarden.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Door onze Site te gebruiken of anderszins uw acceptatie aan te geven (bijvoorbeeld, akkoord te gaan door in te loggen of in te loggen op uw account, te klikken op "Ik ga akkoord", enz.), verklaart en garandeert u dat u de voorwaarden hebt gelezen, begrepen hebt, er mee instemt gebonden te zijn aan deze voorwaarden. Als u niet akkoord gaat met deze Voorwaarden, mag u onze Site niet bezoeken of gebruiken.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Gepersonaliseerde Video’s
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Via onze Site heeft u de mogelijkheid om gepersonaliseerde video’s ("Gepersonaliseerde Video") te kopen bekende personen (“STAR”). U kunt een aanvraag voor een gepersonaliseerde video indienen bij een STAR via onze site, ten behoeve van uzelf of een derde partij, die u identificeert als de ontvangende partij ("ontvanger"). Bij het indienen van een verzoek stemt u ermee in geen valse identiteit te gebruiken of valse informatie te verstrekken. U stemt ermee in onze Site niet te gebruiken als u eerder bent verwijderd of verbannen van onze Site.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en gaat ermee akkoord dat de STAR naar eigen inzicht bepaalt hoe aan uw verzoek en aan de inhoud van de gemaakte gepersonaliseerde-video wordt voldaan (en uw verzoek mogelijk niet exact volgt). De STAR heeft maximaal zeven dagen (naar eigen invulling) om aan uw verzoek te voldoen of dit te weigeren. U kan een gepersonaliseerde video niet retourneren of ruilen en er worden geen terugbetalingen gedaan, tenzij de door u geboekte STAR niet binnen zeven dagen de gepersonaliseerde video oplevert. In dat geval krijgt u automatisch uw betaling geretourneerd, min eventuele ‘betalingfees’. We behouden ons het recht voor om elk verzoek naar eigen inzicht te weigeren.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Afhankelijk van uw volledige betaling voor elke gepersonaliseerde video die u aanvraagt, verleent de STAR u hierbij bepaalde rechten om de gepersonaliseerde video uitsluitend voor uw eigen persoonlijke, niet-commerciële en niet-promotionele doeleinden te gebruiken, onder voorbehoud van deze voorwaarden: een niet -exclusieve, royaltyvrije, volledig betaalde, wereldwijde, sublicentieerbare, herroepbare licentie om die gepersonaliseerde video’s te gebruiken, reproduceren, distribueren en openbaar weer te geven, in alle media, nu bekend of hierna uitgevonden (bijvoorbeeld op sociale mediaplatforms ). De gepersonaliseerde video’s hebben een licentie en worden niet verkocht. U mag uw rechten op een gepersonaliseerde video niet verkopen of doorverkopen. U mag uw rechten op een gepersonaliseerde video alleen in sublicentie geven voor zover dat nodig is om de gepersonaliseerde video te gebruiken zoals toegestaan onder deze voorwaarden (bijvoorbeeld delen met vrienden op een social media-platform, verzenden naar een ontvanger voor persoonlijke, niet- commerciële en niet-promotionele doeleinden die onder deze voorwaarden vallen). U mag een gepersonaliseerde video alleen gebruiken in overeenstemming met deze Voorwaarden, inclusief de acceptabele gebruiksbeperkingen. We kunnen de voorgaande licenties op elk moment om welke reden dan ook beëindigen. We behouden ons het recht voor om een  gepersonaliseerde video op elk gewenst moment en om welke reden dan ook zonder kennisgeving aan u te verwijderen.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en gaat ermee akkoord dat u geen verwachting van privacy hebt met betrekking tot een gepersonaliseerde video die door u wordt gevraagd of een inzending (hieronder gedefinieerd) die u maakt, en dat u geen verzoek of inzending indient die inbreuk maakt op de privacy of andere rechten van een derde partij. U erkent dat het Vidstar-watermerk, indien aanwezig, op elke gepersonaliseerde video intact moet blijven en u stemt ermee in het watermerk van een gepersonaliseerde video niet te bewerken, te wijzigen, aan te passen of te verwijderen of derden hierbij te helpen of aan te moedigen. U stemt er verder mee in om geen afgeleide werken van een gepersonaliseerde video te bewerken, te wijzigen, aan te passen of aan te moedigen of derden te helpen dit te doen. Als u inbreuk maakt op bepalingen van deze Voorwaarden, wordt uw licentie om de gepersonaliseerde video te gebruiken beëindigd en moet u onmiddellijk alle kopieën van de gepersonaliseerde video die u bezit of beheert verwijderen, inclusief op elk platform voor sociale media. 
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U kunt een account op onze Site maken. Bij het maken van een account stemt u ermee in geen valse identiteit te gebruiken of valse informatie te verstrekken, of de Site voor commercieel gewin te gebruiken. U stemt ermee in geen account aan te maken als u eerder bent verwijderd of verbannen van onze site. Als we redelijkerwijs geloven dat u dit hebt gedaan, hebben we het recht om uw account en uw gebruik van enig deel van onze Site op te schorten of te beëindigen. U bent verantwoordelijk voor het handhaven van de vertrouwelijkheid van uw Site-accountinformatie, inclusief uw gebruikersnaam en wachtwoord. U bent verantwoordelijk voor alle activiteiten die plaatsvinden op of in verband met uw siteaccount en u stemt ermee in ons onmiddellijk op de hoogte te stellen van ongeoorloofde toegang of gebruik van uw siteaccount. U erkent en gaat ermee akkoord dat wij niet verantwoordelijk of aansprakelijk zijn voor schade, verliezen, kosten, uitgaven of aansprakelijkheden met betrekking tot ongeoorloofde toegang tot of gebruik van uw siteaccount.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Aanvullende voorwaarden
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Sommige producten of diensten die via de Site worden aangeboden, kunnen aanvullende voorwaarden hebben ("Aanvullende voorwaarden"). Als er aanvullende voorwaarden van toepassing zijn, stellen we deze beschikbaar om te lezen in verband met dat product of die dienst. Door dat product of die dienst te gebruiken, gaat u akkoord met de Aanvullende voorwaarden. Voor zover de Aanvullende voorwaarden in strijd zijn met een van deze Voorwaarden, zijn deze Voorwaarden van toepassing tenzij anders vermeld in dergelijke Aanvullende Voorwaarden.      
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Geschiktheid
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Leeftijd: u moet ten minste 13 jaar oud zijn om onze Site te gebruiken.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Verklaringen en garanties: U verklaart en garandeert dat:
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	het u niet verboden is om enig aspect van onze site, of sites die door ons gemaakt zijn, te gebruiken of daartoe toegang te krijgen door toepasselijke wet- of regelgeving;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	u zult voldoen aan alle voorwaarden die wij stellen, en die we van tijd tot tijd kunnen herzien, zoals die van een door ons geselecteerde externe betalingsprovider, waar u niet op een verboden lijst van die externe betalingsprovider staat;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	we niet eerder een account die u hebt gemaakt of beheerd, uitgeschakeld hebben wegens schending van wet- of regelgeving, schending van rechten van derden of schending van een van deze Voorwaarden; en
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	u geen veroordeelde zedendelinquent bent.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Exportcontrole
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U mag geen enkel deel van onze Site gebruiken, exporteren, importeren of overdragen, behalve zoals toegestaan door de Belgische wetgeving, of enige andere toepasselijke wetgeving. In het bijzonder, maar zonder beperking, mag geen enkel deel van onze Site worden geëxporteerd. U zult onze Site ook niet gebruiken voor enig wettelijk verboden doel.      
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Vergoedingen en betaling
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Vergoedingen.      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        De kosten voor een gepersonaliseerde video worden gespecificeerd op de boekingspagina van de STAR op onze Site op het moment dat u uw verzoek indient. U stemt ermee in om alle verschuldigde bedragen te betalen in overeenstemming met de geldende betalingsvoorwaarden op het moment dat u uw verzoek indient..      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Betaling.      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U kunt een gepersonaliseerde video aanvragen via de website. De betaling dient voldaan te zijn alvorens de gepersonaliseerde video opgenomen wordt..      
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Herroepingsrecht digitale inhoud.      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Om de gepersonaliseerde video zo snel mogelijk aan u ter beschikking te stellen, leveren wij deze binnen 7 dagen. U gaat akkoord met de levering van de digitale inhoud tijdens de wettelijke bedenktermijn, en ziet bij het plaatsen van uw bestelling af van uw herroepingsrecht. .      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Recht op ontbinding. .      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        De STAR zal uw aanwijzingen zo goed mogelijk opvolgen binnen de kaders van artikel Aanvaardbaar gebruik van onze site. U stemt ermee in dat de STAR in uw aangevraagde gepersonaliseerde video artistieke vrijheid heeft en tot op zekere hoogte af kan wijken van de boodschap in uw aanvraag. U stemt er mee in dat u afstand doet op uw recht op ontbinding. .      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Vragen over betalingen. .      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Als u een vraag heeft over een vergoeding, kunnen wij naar eigen inzicht bepalen hoe geschillen over facturering worden opgelost. U stemt ermee in zich te houden aan de voorwaarden van de door ons geselecteerde externe betalingsprovider, die naar eigen inzicht kunnen worden gewijzigd..      
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Eigendom      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en gaat ermee akkoord dat elke gepersonaliseerde video eigendom is van de STAR die deze heeft gemaakt.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Wij of onze licentiegevers bezitten alle rechten, titels en belangen in en om: (i) onze Site en het 'uiterlijk' van onze Site, inclusief alle software, ideeën, processen, gegevens, tekst, media en andere beschikbare inhoud op onze Site (individueel en collectief, "VIDSTAR content"); en (ii) onze handelsmerken, logo's en merkelementen ("Merken"). onze Site, VIDSTAR-inhoud en merken zijn elk beschermd door Belgische en internationale wetgeving. U mag geen enkel deel van de HTML / CSS, JavaScript, visuele ontwerpelementen of concepten dupliceren, kopiëren of hergebruiken zonder onze voorafgaande uitdrukkelijke schriftelijke toestemming.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U verleent ons hierbij een niet-exclusieve, royaltyvrije, volledig betaalde, onbeperkte, wereldwijde, sublicentieerbare, eeuwigdurende en onherroepelijke licentie, op elke manier en in alle media, nu bekend of hierna uitgevonden (inclusief sociale mediaplatforms), om het volgende te gebruiken, reproduceren, in licentie te geven, te distribueren, te wijzigen, aan te passen, opnieuw te formatteren, publiekelijk uit te voeren, publiekelijk te tonen, afgeleide werken van te maken en anderszins te gebruiken voor het uitvoeren en aanbieden van onze Site, het ontwikkelen en het verbeteren van onze producten en diensten, en reclame, marketing en het promoten van onze Site en onze producten en diensten: (i) elk verzoek (video, tekst of anderszins) dat u doet aan een STAR, inclusief informatie over een ontvanger; en (ii) elke inzending die u doet aan VIDSTAR, hetzij via onze Site, een social media-platform of een website van derden, of anderszins, inclusief een reactievideo, idee, intellectueel eigendom, publiciteitsrechten, feedback (hieronder gedefinieerd), beoordeling, foto, video, e-mail, tekst, bericht of andere communicatie, ongeacht of deze betrekking heeft op u of een derde partij ((i) en (ii) elk, afzonderlijk en gezamenlijk, een 'Inzending'). U verklaart en garandeert dat u: (i) alle rechten op een inzending bezit; of (ii) alle benodigde rechten heeft, inclusief met betrekking tot een derde partij die heeft bijgedragen aan, is opgenomen in of waarnaar wordt verwezen in een Inzending, om ons de voorgaande rechten te verlenen. U zult geen Inzending doen die vertrouwelijk of eigendomsrechtelijk is en geen Inzending bevat of bevat informatie die u niet het recht hebt om te onthullen of die u of een Ontvanger niet wenst te worden bekendgemaakt. VIDSTAR is niet verantwoordelijk of aansprakelijk voor enig gebruik of openbaarmaking van een Inzending, inclusief persoonlijke informatie van, of toebehorend aan, u of een derde partij.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        We kunnen om welke reden dan ook een Inzending weigeren, of weigeren te verzenden, of weigeren een Inzending van onze Site te verwijderen. Verder behouden we ons het recht voor om te beslissen of een Inzending deze Voorwaarden schendt en kunnen we op elk moment, zonder kennisgeving aan u en naar eigen inzicht, uw Inzending verwijderen, u blokkeren van onze Site, uw toegang beëindigen of andere passende actie ondernemen wegens schending van deze voorwaarden.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        VIDSTAR wil de mogelijkheid van toekomstige misverstanden vermijden wanneer projecten die door ons, onze medewerkers of onze aannemers zijn ontwikkeld, vergelijkbaar lijken met uw inzending. Als uw inzending bestaat uit ideeën, suggesties, voorstellen, plannen of ander materiaal met betrekking tot ons bedrijf (individueel en gezamenlijk, “Feedback”), erkent en stemt u ermee in dat u die Feedback op eigen risico indient en dat VIDSTAR geen verplichting (inclusief geen verplichting tot vertrouwelijkheid of privacy) met betrekking tot die Feedback, en u verleent VIDSTAR een niet-exclusieve, royaltyvrije, volledig betaalde, onbeperkte, wereldwijde, sublicentie, eeuwigdurend en onherroepelijke licentie, op alle mogelijke manieren en media, nu bekend of hierna uitgevonden, om te reproduceren, in licentie te geven, te distribueren, te wijzigen, aan te passen, publiekelijk uit te voeren, publiekelijk weer te geven, afgeleide werken te maken van (bijvoorbeeld vertalingen, aanpassingen of andere wijzigingen) en anderszins op enigerlei wijze (inclusief commercieel) alle Feedback gebruiken en exploiteren.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U doet hierbij afstand van morele rechten die u mogelijk heeft in een Inzending, inclusief Feedback.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Aanvaardbaar gebruik van onze site      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Uw verantwoordelijkheden: u bent verantwoordelijk voor uw gebruik van elke gepersonaliseerde video, uw siteaccount, onze Site en elke inzending. Ons doel is om een positieve, nuttige en veilige gebruikerservaring te creëren. Om dit doel te promoten, verbieden we bepaalde soorten gedrag die schadelijk kunnen zijn. U verklaart en garandeert dat: (i) u het gebruik van gepersonaliseerde video niet zult gebruiken of autoriseren voor andere doeleinden dan de persoonlijke, niet-commerciële en niet-promotionele doeleinden en (ii) bij het gebruik van een gepersonaliseerde video, uw siteaccount en onze site, en bij het indienen van een inzending, zult u geen:
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	wet, regelgeving of een gerechtelijk bevel overtreden;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	inbreuk maken op, of misbruik maken van de intellectuele eigendom, privacy, publiciteit, morele of andere wettelijke rechten van derden;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	actie ondernemen die iets doet, plaatst, deelt of communiceert wat aanzet of aanmoedigt tot actie die, expliciet of impliciet: illegaal, beledigend, intimiderend, bedreigend, hatelijk, racistisch, denigrerend, schadelijk voor elke reputatie, pornografisch, onfatsoenlijk, obsceen of anderszins aanstootgevend is;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	reclame of commerciële communicatie, inclusief spam, of andere ongevraagde of ongeautoriseerde communicatie sturen;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	activiteiten bezigen met als doel het bespieden of oogsten van gegevens, of software gebruiken, inclusief spyware, ontworpen om gegevens van onze Site te verzamelen, inclusief gegevens van elke gebruiker van onze Site;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	virussen versturen of op andere wijze overdragen, andere computerinstructies of technologische middelen inzetten die bedoeld zijn om het gebruik van computers of gerelateerde systemen te verstoren, beschadigen of vernietigen;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	derden stalken, lastigvallen, bedreigen of schaden;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	onjuiste informatie over uzelf verstrekken, of zich voordoen als een derde;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	deelname plegen aan frauduleuze of illegale activiteiten, waaronder phishing, witwassen van geld of fraude;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	middel(en) gebruiken om enig deel van onze Site te "scrapen" of te automatisch, of regelmatig te doorzoeken;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	activiteiten ontplooien om te proberen om het met welke technologische maatregel die door ons, een van onze providers of een andere derde partij (inclusief een andere gebruiker) is geïmplementeerd om ons, onze Site, gebruikers, ontvangers of andere derde partij te omzeilen;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	toegang tot onze Site om informatie te verkrijgen om een vergelijkbare of concurrerende website, applicatie of service te bouwen;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	pogingen ondernemen de software of andere onderliggende code die wordt gebruikt om onze Site te leveren, te ontcijferen, decompileren, demonteren of reverse engineeren; of
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een derde partij bepleiten, assisteren, aanmoedigen of helpen bij het doen van een van de voorgaande.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Onderzoeken      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en stemt ermee in dat wij niet verplicht zijn om de toegang tot of het gebruik van onze Site door u of derden te controleren (inclusief het bewaken van Inzendingen), maar we hebben het recht om dit te doen om onze Site te exploiteren, om naleving van deze Voorwaarden, om te voldoen aan de toepasselijke wet, regelgeving, gerechtelijk bevel of ander juridisch, administratief of regelgevend verzoek of proces, of anderszins.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Zonder onze rechten te beperken, erkent en stemt u ermee in dat elk verzoek dat u voor een gepersonaliseerde video indient, door ons of door een STAR kan worden afgewezen; als dat meer dan eens gebeurt, kunnen we u van onze Site blokkeren, uw toegang beëindigen of andere passende actie ondernemen.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Auteursrecht en intellectueel eigendomsbeleid      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Beëindigingsbeleid: als we vaststellen dat u herhaaldelijk inbreuk maakt op het auteursrecht, kunnen we uw toegang tot onze Site beëindigen en naar eigen inzicht andere passende maatregelen nemen.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Kennisgeving Digital Millennium Copyright Act: we reageren op kennisgevingen van vermeende inbreuk op het auteursrecht en beëindigen de toegang tot onze Site voor herhaalde inbreukmakers. Als u van mening bent dat uw materiaal is gekopieerd op een manier die inbreuk maakt op het auteursrecht, stuur dan de volgende informatie door naar de onderstaande auteursrechtenagent:
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	uw adres, telefoonnummer en e-mailadres;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een beschrijving van het werk waarop volgens u inbreuk wordt gemaakt;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een beschrijving van het materiaal dat volgens u inbreuk maakt en verzoekt om verwijdering, samen met informatie over waar het zich bevindt;
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een verklaring dat u "te goeder trouw gelooft dat het gebruik van het materiaal op de manier waarover wordt geklaagd niet is geautoriseerd door de eigenaar van het auteursrecht, zijn vertegenwoordiger of de wet.";
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een elektronische of fysieke handtekening van de auteursrechthebbende (of een persoon die bevoegd is om voor de auteursrechteigenaar te handelen); en
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        •	een verklaring van u, op straffe van meineed, dat de door u verstrekte informatie juist is en dat u de auteursrechthebbende bent of bevoegd bent om namens de auteursrechthebbende te handelen.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Copyright-agent: VIDSTAR, te bereiken via help@vidstar.be
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en stemt ermee in dat als u de vereisten niet naleeft, uw kennisgeving mogelijk niet geldig is. Voor alle duidelijkheid: alleen kennisgevingen van vermeende inbreuken op het auteursrecht moeten worden gericht aan onze auteursrechtenagent.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Privacy
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Uw privacy is belangrijk voor ons. Ons
      </span>
      <span className={styles.InfoText}>
        <Link className={styles.TextLink} to="/Privacy/"> privacybeleid </Link>
      </span>
      <span className={styles.InfoText}>
        legt uit hoe we persoonlijke informatie en andere gegevens verzamelen, gebruiken en delen. Door onze Site te gebruiken, gaat u akkoord met ons privacybeleid.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Inhoud en interacties van derden
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Onze Site kan functies en functionaliteiten bevatten die kunnen verwijzen naar of toegang bieden tot inhoud van derden, inclusief gepersonaliseerde video, die volledig onafhankelijk van ons is, inclusief websites, platforms, mappen, servers, netwerken, systemen, informatie, databases, applicaties, software, programma's, producten of diensten en internet in het algemeen. Uw interacties met derde partijen, inclusief gebruikers en STAR’s, die op of via onze Site worden gevonden, zijn uitsluitend tussen u en de derde partij; VIDSTAR kan echter naar eigen inzicht tussenbeide komen in elk geschil en u zult redelijkerwijs samenwerken met VIDSTAR als dit het geval is. U moet elk onderzoek doen dat u nodig of passend acht voordat u doorgaat met de interactie.        
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U stemt ermee in geen contact te maken met of interactie te hebben met een STAR, behalve zoals uitdrukkelijk toegestaan via onze site. U erkent en stemt ermee in dat wij niet verantwoordelijk of aansprakelijk zijn voor enige schade, verliezen, kosten, uitgaven of aansprakelijkheden van welke aard of aard dan ook die het gevolg zijn van een dergelijke interactie. U stelt hierbij elke VIDSTAR-partij (hieronder gedefinieerd) vrij van claims, eisen en schadevergoedingen (feitelijk en indirect) van welke aard of aard dan ook, bekend en onbekend, vermoed en onverwacht, bekendgemaakt en niet bekendgemaakt, voortkomend uit of op enige wijze gerelateerd aan dergelijke geschillen of onze site.      
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Van tijd tot tijd kan een goed doel worden geïdentificeerd op de boekingspagina van een STAR of worden geassocieerd met de STAR elders op onze Site ("goed doel"). De boekingspagina van de STAR kan bijvoorbeeld aangeven dat alle of een deel van de inkomsten van de STAR uit een gepersonaliseerde video aan het goede doel worden gegeven. Al dergelijke afspraken worden strikt gemaakt tussen de STAR en het goede doel. VIDSTAR is geen sponsor van en onderschrijft het goede doel niet en is geen commerciële deelpartij met betrekking tot dergelijke regelingen (tenzij uitdrukkelijk schriftelijk anders vermeld door VIDSTAR). Tenzij uitdrukkelijk vermeld, heeft VIDSTAR geen controle over en geeft geen garanties over het goede doel of enige schenking aan het goede doel.      
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Links     
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Onze Site kan links bevatten naar sociale mediaplatforms of websites van derden. U erkent en gaat ermee akkoord dat: (a) de link niet betekent dat wij het platform of de website onderschrijven of ermee zijn verbonden; en (b) wij zijn niet verantwoordelijk of aansprakelijk voor schade, verliezen, kosten, uitgaven of aansprakelijkheden met betrekking tot uw gebruik van het platform of de website. Lees altijd eerst de algemene voorwaarden en het privacybeleid van het platform of de website voordat u deze gebruikt.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Wijzigingen aan onze site     
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        We verbeteren en updaten onze Site vaak. U erkent en stemt ermee in dat we elk aspect van onze Site op elk gewenst moment kunnen wijzigen of beëindigen, met of zonder kennisgeving aan u.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Beëindiging en voorbehoud van rechten
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U kunt op elk gewenst moment stoppen met het gebruik van onze site. U kunt uw siteaccount op elk gewenst moment annuleren door contact met ons op te nemen via help@vidstar.be. We behouden ons het recht voor om naar eigen inzicht de toegang tot onze Site te beëindigen voor elke persoon, inclusief u, op elk gewenst moment en om welke reden dan ook. Als u een van deze voorwaarden schendt, wordt uw toestemming om onze Site te gebruiken automatisch beëindigd.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Schadeloosstelling     
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U stemt ermee in VIDSTAR en dochterondernemingen, gelieerde ondernemingen, functionarissen, werknemers, directeuren, aandeelhouders, agenten, partners en licentiegevers (elk een "VIDSTAR-partij" en gezamenlijk "VIDSTAR-partijen") en "VIDSTAR-partijen" te vrijwaren en te verdedigen tegen en tegen alle claims, kosten, procedures, eisen, verliezen, schade en kosten (inclusief redelijke advocatenhonoraria en kosten) van welke aard of aard dan ook, die voortvloeien uit, of verband houden met: (a) elke feitelijke of vermeende schending van deze Voorwaarden door u of iemand die uw Site-account gebruikt; (b) enige daadwerkelijke of vermeende schending van wetten of voorschriften of schending van rechten van derden door u of iemand die uw Site-account gebruikt; (c) uw nalatigheid, wangedrag of fraude; (d) elke inzending; (e) enige actie of inactiviteit door u of iemand die namens u handelt; of (f) voor zover toegestaan door de toepasselijke wetgeving, met betrekking tot uw gebruik van onze Site. VIDSTAR heeft het recht om de door u te betalen raadsman te selecteren in verband met enige schadevergoeding door u. U zult redelijkerwijs met ons samenwerken in verband met eventuele schadevergoedingszaken.
      </span>
      <br />
      <br />
      <br />
      <span className={styles.Subtitle}>
        Disclaimers en beperkingen op onze aansprakelijkheid     
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en gaat ermee akkoord dat uw gebruik van onze Site op eigen risico is. Voor zover toegestaan door de toepasselijke wetgeving, wijzen de VIDSTAR-partijen alle garanties, voorwaarden en verklaringen van welke aard dan ook, expliciet, impliciet, wettelijk of anderszins af, inclusief die met betrekking tot verhandelbaarheid, geschiktheid voor een bepaald doel en niet-inbreuk en die voortkomen uit natuurlijk handelen of gebruik van handel.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        In het bijzonder geven de VIDSTAR-partijen geen verklaringen of garanties met betrekking tot de nauwkeurigheid of volledigheid van de inhoud die beschikbaar is op of via onze Site of de inhoud van een sociaal mediaplatform of een website van derden die is gekoppeld aan of geïntegreerd met onze Site. U erkent en stemt ermee in dat de VIDSTAR-partijen niet aansprakelijk zijn voor: (i) fouten of onnauwkeurigheden in de inhoud; (ii) persoonlijk letsel, materiële schade of andere schade die voortvloeit uit uw toegang tot of gebruik van onze Site; (iii) ongeautoriseerde toegang tot of gebruik van onze servers, persoonlijke informatie of gebruikersgegevens; (iv) een onderbreking van de verzending naar of van onze Site; (v) bugs, virussen, Trojaanse paarden en dergelijke die op of via onze Site kunnen worden overgedragen; of (vi) schade, verliezen, kosten, uitgaven of aansprakelijkheden van welke aard dan ook die zijn ontstaan als gevolg van het gebruik van inhoud die via onze Site is geplaatst of gedeeld.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en stemt ermee in dat alle materiaal of informatie gedownload of anderszins verkregen via onze Site, inclusief elke gepersonaliseerde video, op eigen risico wordt gedaan en dat u als enige verantwoordelijk bent voor eventuele schade, verliezen, kosten, uitgaven of aansprakelijkheden die voortvloeien uit of in verband daarmee. Geen enkel advies of informatie, mondeling of schriftelijk, verkregen door u van ons of via onze Site, inclusief via een gepersonaliseerde video, creëert een garantie die niet uitdrukkelijk door ons is gegeven.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U erkent en stemt ermee in dat wanneer u onze Site gebruikt, u wordt blootgesteld aan inhoud uit verschillende bronnen en dat VIDSTAR niet verantwoordelijk is voor de nauwkeurigheid, bruikbaarheid, veiligheid, wettigheid of intellectuele eigendomsrechten van of met betrekking tot dergelijke inhoud. U begrijpt en erkent verder dat u mogelijk wordt blootgesteld aan inhoud die onnauwkeurig, aanstootgevend, onfatsoenlijk, of intimiderend is, en u stemt ermee in af te zien van en af te zien van alle wettelijke of billijke rechten of rechtsmiddelen die u hebt of kunt hebben tegen enige VIDSTAR-partij met respect daarvoor.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Voor zover toegestaan door de toepasselijke wetgeving, erkent en stemt u ermee in dat geen enkele VIDSTAR-partij aansprakelijk is jegens u of een derde voor enige indirecte, speciale, incidentele, punitieve of gevolgschade (inclusief winstderving, of derving van inkomsten) of voor de kosten voor het verkrijgen van vervangende producten, voortvloeiend uit of in verband met deze Voorwaarden, ongeacht de oorzaak, ongeacht of dergelijke aansprakelijkheid voortvloeit uit een claim op basis van contract, garantie, onrechtmatige daad (inclusief nalatigheid), strikte aansprakelijkheid of anderszins en of VIDSTAR al dan niet is geïnformeerd over de mogelijkheid van dergelijke schade.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Voor zover maximaal is toegestaan door de toepasselijke wetgeving, is onze totale cumulatieve aansprakelijkheid jegens u of een derde partij onder deze Voorwaarden, van alle oorzaken van actie en alle theorieën van aansprakelijkheid, beperkt tot en zal niet hoger zijn dan de kosten die VIDSTAR daadwerkelijk van u heeft ontvangen gedurende de 12 maanden voorafgaand aan de claim die aanleiding geeft tot een dergelijke aansprakelijkheid.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        Bepaalde rechtsgebieden staan de uitsluiting of beperking van bepaalde schade niet toe. Als die wetten op u van toepassing zijn, zijn sommige of alle bovengenoemde uitsluitingen of beperkingen mogelijk niet op u van toepassing en hebt u mogelijk aanvullende rechten.
      </span>
      <br />
      <br />
      <span className={styles.InfoText}>
        U stemt ermee in dat de hierboven uiteengezette beperkingen van schadevergoeding fundamentele elementen zijn van de basis van de overeenkomst tussen VIDSTARen u.
      </span>

    </div>
  );
}

export default Terms;