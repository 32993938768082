import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import styles from "./TopNavigation.module.css";
import logo from "./Watermerk.png";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const TopNavigation = () => (
  <Navbar collapseOnSelect expand="md" sticky="top" className={styles.Header}>
    <Navbar.Brand>
      <Link to="/">
        <img alt="logo" src={logo} className={styles.logoimg} />{" "}
      </Link>
    </Navbar.Brand> 
    <Nav className="mr-auto"/>
    {/* <Input
      className={styles.NavbarSearch}
      placeholder="Zoeken..."
      prefix={<SearchOutlined className={styles.Prefix}/> }
      size="small"
    /> */}
    <Navbar.Toggle aria-controls="responsive-navbar-nav" >
      <MenuOutlined className={styles.HamburgerIcon}/>
    </Navbar.Toggle>
    <Navbar.Collapse id="responsive-navbar-nav " className={styles.navbarcollapsecustom}>
      <Nav className="mr-auto">
        <Nav.Link>
          <Link to="/">
            <span className={styles.NavbarHow}>
              <Trans>
                TopNavigation.Menu
              </Trans>
            </span>
          </Link>
        </Nav.Link>
      </Nav>  
    </Navbar.Collapse>
  </Navbar>
);

export default TopNavigation;
