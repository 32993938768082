import React from "react";
import styles from "./TopBlockSecretPage.module.css";
import Apple from "./AppStoreBadge.png";
import AppleLaptop from "./AppleLaptop.png";
import Google from "./PlayStoreBadge.png";
import Insta from "./VidstarInstaProfile.png";


const TopBlockSecretPage = () => (
  <div className={styles.Background}>
    <div className={styles.Frame1}>
      <div className={styles.Column1}>
        <div className={styles.Row1}>
          <span className={styles.Text1}>
            Laat je fans ... WOW zeggen!
          </span>
        </div> 
        <div className={styles.Row1}>
          <span className={styles.Text2}>
            Een betekenisvolle connectie met jouw fans via een snelle gepersonaliseerde video. Dat is de magie van Vidstar!
          </span>
        </div> 
        <div className={styles.Row3}>
          <div className={styles.Badge1}>
          </div>
          <div className={styles.Badge1}>
            <a style={{display: "table-cell"}} href = "https://apps.apple.com/be/app/vidstar/id1544865377?l=nl" target = "_blank" rel = "noopener noreferrer">
              <img src={Apple} alt='applestore' className={styles.Image1} />
            </a>
          </div>
          <div className={styles.Badge2}>
            <a style={{display: "table-cell"}} href = "https://play.google.com/store/apps/details?id=com.Vidstar.Vidstar" target = "_blank" rel = "noopener noreferrer">
              <img src={Google} alt='playstore' className={styles.Image2} />
            </a>
          </div>
          <div className={styles.Badge1}>
          </div>
        </div> 
        <div className={styles.Row4}>
          <span className={styles.InviteCodeText}>
            InviteCode: <span className={styles.InviteCodeTextBig}>123456</span>
          </span>
        </div>
      </div>
      <div className={styles.Column2}>
        <img src={AppleLaptop} alt='appleLaptop' className={styles.Image3} />
          <div className={styles.VideoScreen}>
            <video
              className={styles.Video}
              preload={'auto'}
              src="https://vidstar-file-storage.ams3.cdn.digitaloceanspaces.com/VidstarSite/VidstarPromoVideoGoed.mp4?fbclid=IwAR2iBOe6k7KUPbaVD7y247-VEHy7VrG69y5eVUmFYXy13G9xAmOGydSQ88c"
              type="video/mp4"
              controls
              poster={Insta}
            />
          </div>
      </div>
    </div>
  </div>
);

export default TopBlockSecretPage;
