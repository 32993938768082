import React from "react";
import styles from "./Order.module.css";

import OrderPicture from "../OrderPicture/OrderPicture";
import OrderInfo from "../OrderInfo/OrderInfo";
import OrderForm from "../OrderForm/OrderForm";
function Order(props) {
  return (
    <div className={styles.Rows}>
      <OrderPicture
        StarName={props.StarName}
        StarInfo={props.StarInfo}
        Pic={props.Pic}
        className={styles.OrderPicture}
      />
      <div className={styles.OrderForm}>
        <OrderForm 
          StarName={props.StarName}
          StarPic={props.Pic}
          StarPrice={props.StarPrice}
          StarId={props.StarId}
        />
      </div>
      <div className={styles.inbetween}></div>
      <div className={styles.OrderInfo}>
        <OrderInfo StarName={props.StarName} />
      </div>
    </div>
  );
}

export default Order;
