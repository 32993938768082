import React, {useState, useEffect} from "react";
import styles from "./BottomBlockSecretPage.module.css";
import Iphone11 from "./Iphone11.png";
import useWindowDimensions from '../WindowDimensions/useWindowDimensions';

function BottomBlockSecretPage() {
const { height, width } = useWindowDimensions();
const [autoPlay, setAutoPlay] = useState (false)

useEffect(() => {
  console.log(width)
  WidthChecker()
}, [width])

const WidthChecker = () =>{
  if ( width <= 800){
    setAutoPlay(false)
  }
  else
  {
    setAutoPlay(true)
  }
}

return (
  <div className={styles.Background} id="Bottom">
    <div className={styles.Column1}>
    <div className={styles.Title}>
        <div className={styles.Kader}>
          <span className={styles.TextTitle}>
            Hoe werkt het?
          </span>
        </div>
      </div>
      <div className={styles.Step1}>
        <div className={styles.Kader}>
          <div className={styles.Icon}>
            <img src='https://d9hhrg4mnvzow.cloudfront.net/talent.cameo.com/cddbb196-personal-computer-1f4bb_100w00w000000000000028.png' alt='Laptop' className={styles.IconImage} />
          </div>
          <div className={styles.TextKader}>
            <span className={styles.Text}><span className={styles.TextBold}></span>
              Fans bezoeken Vidstar en zenden <span className={styles.TextBold}>jou een verzoek</span>.
            </span>
          </div>
        </div>
      </div>
      <div className={styles.Step2}>
        <div className={styles.Kader}>
          <div className={styles.Icon}>
            <img src='https://d9hhrg4mnvzow.cloudfront.net/talent.cameo.com/725dd48e-asset-8.svg' alt='Duimpje' className={styles.IconImage} />
          </div>
          <div className={styles.TextKader}>
            <span className={styles.Text}>
              Beheer je verzoeken <span className={styles.TextBold}>in de app</span>. Je hebt <span className={styles.TextBold}>7 dagen</span> om je verzoeken te accepteren (of te weigeren).
            </span>
          </div>
        </div>
      </div>
      <div className={styles.Step3}>
        <div className={styles.Kader}>
          <div className={styles.Icon}>
            <img src='https://d9hhrg4mnvzow.cloudfront.net/talent.cameo.com/ceecf51d-asset-5.svg' alt='Duimpje' className={styles.IconImage} />
          </div>
          <div className={styles.TextKader}>
            <span className={styles.Text}>
            <span className={styles.TextBold}>30 seconden</span> per video. Langer mag ook!
            </span>
          </div>
        </div>
      </div>
      <div className={styles.Step4}>
        <div className={styles.Kader}>
          <div className={styles.Icon}>
            <img src='https://d9hhrg4mnvzow.cloudfront.net/talent.cameo.com/97527e85-asset-9.svg' alt='Geldzak' className={styles.IconImage} />
          </div>
          <div className={styles.TextKader}>
            <span className={styles.Text}>
            <span className={styles.TextBold}>Bepaal je eigen prijs</span> en behoud <span className={styles.TextBold}>80%</span> van je inkomsten*.
            </span>
          </div>
        </div>
      </div>
      <div className={styles.Step4}>
        <div className={styles.Kader}>
          <div className={styles.SubTextKader}>
            <span className={styles.TextSub}>
              *Steun je een goed doel? Dan behoud je 90%.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.Column2}>
      <div className={styles.ImageScreen}>
        <img src={Iphone11} alt='Iphone11' className={styles.ImageIphone} />
      </div>
            <video
              className={styles.Video}
              preload={'auto'}
              src="https://vidstar-file-storage.ams3.cdn.digitaloceanspaces.com/VidstarSite/IphoneVideo.mp4?fbclid=IwAR0Ri77GMehyKXmDpm7NL1zcuT0SR4CUzijPYd7Zw56DZzxNBbf-bedkKO4"
              type="video/mp4"
              autoPlay={autoPlay}
              muted
              loop
            />
    </div> 
  </div>
)
};


export default BottomBlockSecretPage;