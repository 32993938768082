import React from "react";
import styles from "./AppLinkSecretPage.module.css";


function AppLinkSecretPage() {
 

  return (
    <div className={styles.Background}>
          <div className={styles.BigBlock}>
            <div className={styles.Block1}>
              <div className={styles.LeftRow1}>
                <span className={styles.TitleText}>
                   Download onze App!
                </span>
              </div>
              <div className={styles.LeftRow2}>
                <a href = "https://apps.apple.com/be/app/vidstar/id1544865377?l=nl" target = "_blank" rel = "noopener noreferrer" className={styles.Button1}>
                  IOS App
                </a>
                <a href = "https://play.google.com/store/apps/details?id=com.Vidstar.Vidstar" target = "_blank" rel = "noopener noreferrer" className={styles.Button2}>
                   Android App
                </a>
              </div>
            </div>
            <div className={styles.Block2}>
            </div>
          </div>
    </div>
  )
}

export default AppLinkSecretPage;