import React,{ useState, useEffect } from "react";
import { Layout } from "antd";
import styles from "./Star.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Info from "../../Components/Info/Info";
import { useParams} from "react-router-dom";
import axios from "axios"

function Star() {
  const [isLoading, setLoading] = useState(true);
  const [alias, setAlias] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [price, setPrice] = useState('');
  const [video, setVideo] = useState('');
  const [poster, setPoster] = useState('');
  let { star } = useParams();

  const RealStar = star.replace("_", " ")

  const apiCall = async()=>{
    axios.get(`https://www.vidstar.be/api/celebrities?_where[Alias]=${RealStar}`)
    .then((res)=>{
      res.data.forEach(element => { 
          const splitVideoUrl = element.ProfileVideo.url.split("/")
          setVideo('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitVideoUrl[splitVideoUrl.length - 1])
          if (Object.keys(element.ProfilePicture.formats).length === 4)
         {
          const splitPhotoUrl = element.ProfilePicture.formats.large.url.split("/")
          setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
         }
         else if (Object.keys(element.ProfilePicture.formats).length === 3)
         {
          const splitPhotoUrl = element.ProfilePicture.formats.medium.url.split("/")
          setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
         }
         else if (Object.keys(element.ProfilePicture.formats).length === 2)
         {
          const splitPhotoUrl = element.ProfilePicture.formats.small.url.split("/")
          setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
         }
         else if (Object.keys(element.ProfilePicture.formats).length === 1)
         {
          const splitPhotoUrl = element.ProfilePicture.formats.thumbnail.url.split("/")
          setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
         }
          setAlias(element.Alias)
          setShortDescription(element.ShortDescription)
          setLongDescription(element.LongDescription)
          setPrice("€ "+element.Price)
      });
    })
    .then(()=>{
        setLoading(false) 
    }).catch((error) => {
      console.log("Oops, request failed!", error)
    })
    .catch((error) => {
      console.log('error1',error.response)
    })
  }

  useEffect(() => {
    apiCall();
  }, [])

  if (isLoading) {
    return (
      <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Info
          StarName={"..."}
          StarShow={"..."}
          StarInfo={"..."}
        />
        {/* <VideoCardRow Title="Recente Video's" /> */}
      </section>
      <Footer />
    </Layout>
    )
  }

  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Info
          StarName={alias}
          StarShow={shortDescription}
          StarInfo={longDescription}
          StarPrice={'Boeken voor ' + price}
          StarVideo={video}
          StarPoster={poster}
          StarUrl={star}
        ></Info>
        {/* <VideoCardRow Title="Recente Video's" /> */}
      </section>
      <Footer />
    </Layout>
  );
}

export default Star;
