import React from "react";
import { Layout } from "antd";
import styles from "./PrivacyPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Privacy from "../../Components/Privacy/Privacy";

function PrivacyPage() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Privacy/>
      </section>
      <Footer />
    </Layout>
  );
}

export default PrivacyPage;