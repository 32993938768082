import React,{useState} from "react";
import styles from "./Enroll.module.css";
import { Trans } from "react-i18next";
import YellowButton from "../YellowButton/YellowButton";
import { Input, Form} from "antd";
import axios from "axios";
import { UserOutlined, MailOutlined, PhoneOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined, StarOutlined } from '@ant-design/icons';




function Enroll() {
  const [disable, setDisable] = useState (false);
  const [completed, setCompleted] = useState ('');
  const [form] = Form.useForm();

  const onFinishFailed = () => {
  };

  const onFinish = async(values)=>{
    setDisable(true)
    axios.post("https://www.vidstar.be/api/Applications/", { 
        'Alias':values.Alias,
        'Email':values.Email, 
        'PhoneNumber':values.PhoneNumber, 
        'FirstName':values.FirstName, 
        'LastName':values.LastName,
        'Instagram':values.Instagram,
        'Twitter':values.Twitter,
        'Youtube':values.Youtube,
        'Tiktok':values.Tiktok,
    })
   .then(()=>{
        setDisable(false)
        form.resetFields();
        setCompleted('Aanvraag voltooid! We nemen binnen de 24u contact met u op.')    
    }).catch(() => {
        setDisable(false)
        setCompleted('Verbinding met de server mislukt!')
    });
  }

  return (
    <div className={(styles.Columns, styles.InfoText)}>
        <span className={styles.Title}>
            Begin vandaag je fans te verwennen! Meld je aan om een profiel aan te maken.
        </span>
        <br />
        <br />
        <span className={styles.SubText}>
            * vereist
        </span>
        <br />
        <span className={styles.Completed}>
            {completed}
        </span>
        <br />
        <Form
            className={styles.OrderForm}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            form={form}
        >
            <Form.Item 
                name="Alias" 
                rules={[
                  {
                    required: true,
                    message: 'Artiestennaam/Alias is vereist',
                  },
                ]}>
                  <Input 
                    className={styles.Input}
                    placeholder={'Alias/Artiestennaam*'}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
            </Form.Item>
            <div className={styles.Rows}>
              <div className={(styles.Columns, styles.Links)}>
                <Form.Item name="Email" rules={[
                  {
                    type: 'email',
                    message: 'Geen geldig Emailadres',
                  },
                  {
                    required: true,
                    message: 'Emailadres is vereist',
                  },
                ]}>
                   <Input 
                    className={styles.Input}
                    placeholder={'Emailadres*'}
                    prefix={<MailOutlined className="site-form-item-icon" />}
                   />
                </Form.Item>
              </div>
              <div className={(styles.Columns, styles.Rechts)}>
                <Form.Item name="PhoneNumber" >
                   <Input 
                    className={styles.Input}
                    placeholder={'Telefoonnummer'}
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.Rows}>
              <div className={(styles.Columns, styles.Links)}>
                <Form.Item 
                  name="FirstName" 
                  rules={[
                    {
                      required: true,
                      message: 'Voornaam is vereist',
                    },
                ]}>
                  <Input 
                    className={styles.Input} 
                    placeholder={'Voornaam*'}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
              <div className={(styles.Columns, styles.Rechts)}>
                <Form.Item name="LastName" >
                  <Input 
                    className={styles.Input} 
                    placeholder={'Achternaam'}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.Rows}>
              <div className={(styles.Columns, styles.Links)}>
                <Form.Item name="Instagram" >
                  <Input 
                    className={styles.Input}
                    placeholder={'Instagram'}
                    prefix={<InstagramOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
              <div className={(styles.Columns, styles.Rechts)}>
                <Form.Item name="Twitter" >
                  <Input 
                    className={styles.Input}
                    placeholder={'Twitter'}
                    prefix={<TwitterOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.Rows}>
              <div className={(styles.Columns, styles.Links)}>
                <Form.Item name="Youtube" >
                  <Input 
                    className={styles.Input}
                    placeholder={'Youtube'}
                    prefix={<YoutubeOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
              <div className={(styles.Columns, styles.Rechts)}>
                <Form.Item name="Tiktok" >
                  <Input 
                    className={styles.Input}
                    placeholder={'TikTok'}
                    prefix={<StarOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item>
                <div className={styles.YellowButton}>
                    <YellowButton
                        className={styles.YellowButton}
                        Text={<Trans>Enroll.ButtonText</Trans>}
                        disabled ={disable}
                    />
                </div>
            </Form.Item>
        </Form>
    </div>
  )
}

export default Enroll;