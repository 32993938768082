import React from "react";
import { Layout } from "antd";
import styles from "./FAQPage.module.css";
import TopNavigationLandingPage from "../../Components/TopNavigationLandingPage/TopNavigationLandingPage";
import Footer from "../../Components/Footer/Footer";
import Faq from "../../Components/Faq/Faq";

function FAQPage() {
  return (
    <Layout>
      <TopNavigationLandingPage/>
      <section className={styles.middle}>
        <Faq/>
      </section>
      <Footer />
    </Layout>
  );
}

export default FAQPage;