import React, {useState, useEffect} from "react";
import styles from "./CheckoutPayment.module.css";
import { Trans } from "react-i18next";
import bancontact from "./bancontact.png";
import Amex from "./Amex.png";
import Mastercard from "./Mastercard.png";
import visa from "./visa.png";
import axios from "axios";
import { UserStore } from "../../Store/UserStore";
import { useStoreState } from "pullstate";

function CheckoutPayment(props) {
  const [isBancontact, setIsBancontact] = useState (false);
  const [isCreditCard, setIsCreditCard] = useState (false);
  const [disable, setDisable] = useState (false);
  const [url, setUrl] = useState ('');
  const celebrityId = useStoreState(UserStore, s => s._id);
  const Email = useStoreState(UserStore, s => s.Email);
  const PhoneNumber = useStoreState(UserStore, s => s.Gsm);
  const Description = useStoreState(UserStore, s => s.Instruction);
  const For = useStoreState(UserStore, s => s.For);
  const From = useStoreState(UserStore, s => s.From);
  const Occasion = useStoreState(UserStore, s => s.Occasion);
  const IsPrivate = useStoreState(UserStore, s => s.Private);
  
  useEffect(() => {
    if( url !== '' ){
    window.location.href = url
    }
  }, [url])

  const handleSubmitBancontact = async () => {
    setDisable(true)
    axios.post("https://www.vidstar.be/api/checkout", { 
      'cardType':'bancontact',
      'celebrityId':celebrityId,
      'Email':Email,
      'PhoneNumber':PhoneNumber,
      'Description':Description,
      'For':For,
      'From':From,
      'Occasion':Occasion,
      'IsPrivate':IsPrivate 
    })
   .then((res)=>{
      setUrl(res.data.redirectUrl)
      setDisable(false)
    }).catch(() => {
      setDisable(false)
    });
  }

  const handleSubmitCreditCard = async () => {
    axios.post("https://www.vidstar.be/api/checkout", { 
      'cardType':'card',
      'celebrityId':celebrityId,
      'Email':Email,
      'PhoneNumber':PhoneNumber,
      'Description':Description,
      'For':For,
      'From':From,
      'Occasion':Occasion,
      'IsPrivate':IsPrivate 
    }).then((res)=>{
      setUrl(res.data.redirectUrl)
      setDisable(false)
    }).catch(() => {
      setDisable(false)
    });
  }

const Bancontact = () => (
  <button type="submit" onClick={handleSubmitBancontact} disabled={disable} className={styles.Button}>
    <Trans>Payment.ButtonText</Trans>
  </button>
)

const CreditCard = () => (
  <button type="submit" onClick={handleSubmitCreditCard} disabled ={disable} className={styles.Button}>
    <Trans>Payment.ButtonText</Trans>
  </button>
)

  return (
    <div>
       <div className={styles.TitleDiv}>
          <span className={styles.PaymentSubTitle}>
             <Trans>Payment.SubTitle3</Trans>{props.Id}
          </span>
          <br/>
        </div>
        <div className={styles.OrderInfo}>
        <button className={styles.Column}
          onClick={() => {
          setIsBancontact(!isBancontact)
        }}>
          <span className={styles.CardInfo}>
            <Trans>Payment.Bancontact</Trans>
          </span>
          <div className={styles.Cards}>
            <img className={styles.bancontact} src={bancontact} alt="Bancontact"/>
          </div>
        </button>
            {isBancontact && Bancontact()}
        </div>
        <div className={styles.OrderInfo}>
        <button className={styles.Column}
          onClick={() => {
          setIsCreditCard(!isCreditCard)
        }}>
          <span className={styles.CardInfo}>
            <Trans>Payment.CreditCard</Trans>
          </span>
          <div className={styles.Cards}>
            <img className={styles.visa} src={visa} alt="Visa"/>
            <img className={styles.mastercard} src={Mastercard} alt="Mastercard"/>
            <img className={styles.amex} src={Amex} alt="Amex"/>
          </div>
        </button>
            {isCreditCard && CreditCard()}
        </div>
    </div>
  );
}

export default CheckoutPayment;
