import React,{useState, useEffect} from "react";
import { Layout } from "antd";
import styles from "./OrderPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Order from "../../Components/Order/Order";
import { useParams} from "react-router-dom";
import axios from "axios"
import { UserStore } from "../../Store/UserStore";

function OrderPage() {
  const [poster, setPoster] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [alias, setAlias] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [price, setPrice] = useState(0);

  let { star } = useParams();
  const RealStar = star.replace("_", " ")
 
  const apiCall = async()=>{
    axios.get(`https://www.vidstar.be/api/celebrities?_where[Alias]=${RealStar}`)
    .then((res)=>{
      res.data.forEach(element => { 
        if (Object.keys(element.ProfilePicture.formats).length === 4)
        {
         const splitPhotoUrl = element.ProfilePicture.formats.large.url.split("/")
         setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
        }
        else if (Object.keys(element.ProfilePicture.formats).length === 3)
        {
         const splitPhotoUrl = element.ProfilePicture.formats.medium.url.split("/")
         setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
        }
        else if (Object.keys(element.ProfilePicture.formats).length === 2)
        {
         const splitPhotoUrl = element.ProfilePicture.formats.small.url.split("/")
         setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
        }
        else if (Object.keys(element.ProfilePicture.formats).length === 1)
        {
         const splitPhotoUrl = element.ProfilePicture.formats.thumbnail.url.split("/")
         setPoster('https://vidstar-file-storage.ams3.digitaloceanspaces.com/'+splitPhotoUrl[splitPhotoUrl.length - 1])
        }
        setAlias(element.Alias)
        setShortDescription(element.ShortDescription)
        setPrice(element.Price)
        UserStore.update(s => {
          s._id = (element._id)
        })
      });
    }).then(()=>{
      setLoading(false) 
    }).catch(() => {
      console.log("Oops, request failed!")
    })
    .catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    apiCall();
  }, [])

  if (isLoading) {
    return (
      <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Order 
          StarName={'...'}
          StarInfo={'...'}
          Pic={''}
          StarPrice={'...'}
        />
      </section>
      <Footer />
    </Layout>
    )
  }

  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Order 
          StarName={alias}
          StarInfo={shortDescription}
          Pic={poster}
          StarPrice={price}
        />
      </section>
      <Footer />
    </Layout>
  );
}

export default OrderPage;
