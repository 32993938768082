import React from "react";
import styles from "./VideoCardDone.module.css";

function VideocardDone(props) {
  return (
    <div className={styles.Card}>
      <video
        className={styles.Video}
        controls
        preload={'auto'}
        src={props.Video}
        type="video/mp4"
      />
    </div>
  );
}

export default VideocardDone;