import React from "react";
import styles from "./Checkout.module.css";
import Checkoutmandje from "../Checkoutmandje/Checkoutmandje";
import CheckoutInfo from "../CheckoutInfo/CheckoutInfo";
import CheckoutPayment from "../CheckoutPayment/CheckoutPayment";

function Checkout(props) {
  return (
    <div>
      <Checkoutmandje
        Pic={props.Pic}
        StarName={props.StarName}
        From={props.From}
        For={props.For}
        Occasion={props.Occasion}
        Instruction={props.Instruction}
        Price={props.Price}
        Me={props.Me}
      />
      <CheckoutInfo
        Email={props.Email}
        Gsm={props.Gsm}
      />
      <CheckoutPayment
        Pic={props.Pic}
        StarName={props.StarName}
        From={props.From}
        For={props.For}
        Occasion={props.Occasion}
        Instruction={props.Instruction}
        Price={props.Price}
        Me={props.Me}
        Id={props.Id}
      />
    </div>
  );
}

export default Checkout;