import React from "react";
import styles from "./WhatIs.module.css";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { faHandPointRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";

const WhatIs = () => (
  <div className={styles.Columns}>
    <span className={styles.Row}>
      <FontAwesomeIcon icon={faPaperPlane} className={styles.IconGrote}/>
         <div className={styles.Text}>
           <Trans>WhatIs.Icon1</Trans>
         </div>
    </span>
    <span className={styles.Row}>
      <FontAwesomeIcon icon={faHandPointRight} className={styles.IconHand}/>
    </span>
    <span className={styles.Row}>
      <FontAwesomeIcon icon={faMobileAlt} className={styles.IconGrote}/>
         <div className={styles.Text}>
           <Trans>WhatIs.Icon2</Trans>
         </div>
    </span>
    <span className={styles.Row}>
      <FontAwesomeIcon icon={faHandPointRight} className={styles.IconHand}/>
    </span>
    <span className={styles.Row}>
      <FontAwesomeIcon icon={faGift} className={styles.IconGrote}/>
         <div className={styles.Text}>
           <Trans>WhatIs.Icon3</Trans>
         </div>
    </span>
  </div>
);

export default WhatIs;
