import React from "react";
import styles from "./OrderInfo.module.css";
import { Trans } from "react-i18next";
import bancontact from "./bancontact.png";
import Amex from "./Amex.png";
import Mastercard from "./Mastercard.png";
import visa from "./visa.png";

function OrderInfo(props) {
  return (
    <div className={styles.OrderInfo}>
       <div className={styles.List}>
          <span className={styles.ListItem}>
            {props.StarName} <Trans>OrderInfo.Item1</Trans>
          </span>
          <span className={styles.ListItemGrijs}>
            <Trans >OrderInfo.SubItem1</Trans>
          </span>
          <br />
          <br />
          <span className={styles.ListItem}>
            <Trans>OrderInfo.Item2</Trans>
          </span>
          <span className={styles.ListItemGrijs}>
            <Trans >OrderInfo.SubItem2</Trans>
          </span>
          <br />
          <br />
          <span className={styles.ListItem}>
            <Trans>OrderInfo.Item3A</Trans>{props.StarName}<Trans>OrderInfo.Item3B</Trans>
          </span>
          <span className={styles.ListItemGrijs}>
            <Trans >OrderInfo.SubItem3</Trans>
          </span>
          <br />
          <br />
          <img className={styles.bancontact} src={bancontact} alt="Bancontact"/>
          <img className={styles.visa} src={visa} alt="Visa"/>
          <img className={styles.mastercard} src={Mastercard} alt="Mastercard"/>
          <img className={styles.amex} src={Amex} alt="Amex"/>
        </div>
    </div>
  );
}

export default OrderInfo;
