import React from "react";
import { Layout } from "antd";
import styles from "./TermsPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import Terms from "../../Components/Terms/Terms";

function TermsPage() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Terms/>
      </section>
      <Footer />
    </Layout>
  );
}

export default TermsPage;