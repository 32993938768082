import React from "react";
import styles from "./Privacy.module.css";

function Privacy(props) {

  return (
  <div className={(styles.Columns, styles.InfoText)}>
    <span className={styles.Title}>
      Privacybeleid
    </span>
    <br />
    <span className={styles.DatumText}>
      Laatst bijgewerkt op 11 december 2020.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Wij verzamelen informatie van u om met VIDSTAR ("wij",  "VIDSTAR") persoonlijke video's en andere producten en diensten via onze website (Vidstar.be) en mobiele applicatie (gezamenlijk aangeduid als onze "Site") te verhandelen. Daarnaast doen we dit ook om onze producten en diensten te blijven verbeteren.  
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Met 'persoonlijke informatie' bedoelen we contact- en andere informatie die u identificeert als een specifieke, identificeerbare persoon.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      In dit privacybeleid leggen we uit hoe VIDSTAR uw informatie verzamelt, opslaat, gebruikt, overdraagt en openbaar maakt, en hoe het van toepassing is op onze Site. 
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Van tijd tot tijd kan VIDSTAR dit Privacybeleid herzien. We zullen u op de hoogte stellen door het herziene privacybeleid op onze site te plaatsen en de datum 'Laatst bijgewerkt' bovenaan dit privacybeleid bij te werken; we kunnen u ook op andere manieren op de hoogte stellen, zoals via de contactgegevens die u hebt verstrekt. Alle wijzigingen in dit privacybeleid zijn onmiddellijk van kracht, tenzij anders vermeld. Door onze site te blijven gebruiken, gaat u akkoord met het herziene privacybeleid. Lees ook de toepasselijke Algemene voorwaarden, die ook van toepassing zijn op het gebruik van onze Site.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      U moet het beleid volledig lezen, maar hier zijn een paar belangrijke dingen:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Wanneer u VIDSTAR gebruikt, ontvangen we zelfs informatie van u, zoals het type apparaat dat u gebruikt en uw IP-adres.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
     U kunt ervoor kiezen om aanvullende informatie met ons te delen, zoals uw e-mailadres, door een account aan te maken.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Als u vragen heeft over dit beleid, hoe we uw persoonlijke gegevens verzamelen of verwerken, of iets anders met betrekking tot onze privacypraktijken, willen we dat graag van u horen. U kunt op elk gewenst moment contact met ons opnemen via de contactgegevens in de sectie Contact aan het einde van dit beleid.   
     </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Toepassingsgebied van VIDSTAR Privacybeleid
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Dit privacybeleid is van toepassing op alle aspecten van onze site.
     </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Dit privacybeleid is niet van toepassing op websites van derden, online-eigendommen, platforms, sociale media of systemen (elk een "eigendom van derden") waarnaar onze site linkt of via welke onze site toegankelijk is. U begrijpt en gaat ermee akkoord dat VIDSTAR niet verantwoordelijk is voor de beveiligings- of privacypraktijken van een eigendom van derden en dat een ander privacybeleid van toepassing kan zijn.
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Informatie die we verzamelen
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Informatie die u verstrekt:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      VIDSTAR kan persoonlijke informatie van u verzamelen wanneer u:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      1.	Een account aanmaakt op de site: VIDSTAR verzamelt uw naam en e-mailadres en kan andere persoonlijke informatie verzamelen, zoals een telefoonnummer wanneer u zich registreert, bijwerkt of informatie toevoegt aan uw account. VIDSTAR verzamelt persoonlijke informatie met betrekking tot een externe account (indien van toepassing) die u koppelt aan uw VIDSTAR -account (zoals uw Instagram-gebruikersnaam als u uw Instagram-account koppelt). We kunnen betalingsinformatie (zoals creditcard- of bankpasgegevens) opslaan, maar alleen als u ervoor kiest deze op te slaan in uw account. We gebruiken deze informatie om uw account te onderhouden en met u over de account te communiceren, zoals door uw e-mailadres of accountinformatie te verifiëren of u te vragen om te reageren op een enquête om de gebruikerservaring op onze site te verbeteren. We zullen uw e-mailadres gebruiken om u promotionele e-mailberichten van VIDSTAR te sturen (en niet van derden) als u ervoor hebt gekozen deze te ontvangen; Klik op de link 'Afmelden' in de voettekst van de promotie-e-mail om te stoppen met het ontvangen ervan.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      2.	Een aankoopt doet via de site: VIDSTAR verzamelt de informatie die u verstrekt bij het aanvragen van een gepersonaliseerde video, inclusief uw naam en e-mailadres, elk mobiel telefoonnummer (van u of de ontvanger) dat u wilt verstrekken, de naam van de ontvanger, en de gegevens die u verstrekt als onderdeel van uw verzoek. VIDSTAR verwerkt betalingsinformatie die u verstrekt wanneer u voor een aankoop betaalt. Wanneer u goederen koopt, verzamelen we ook verzend- en leveringsinformatie. Wanneer u een gepersonaliseerde video bestelt, maken we, tenzij u zich afmeldt, uw naam, de naam van de ontvanger en de gepersonaliseerde video zelf openbaar op onze site. We gebruiken deze informatie om aan uw verzoek te voldoen, uw betaling te accepteren, uw bestellingen te verzenden en af te leveren, e-mailberichten af te leveren en informatie te verwerken die u via onze site verstrekt. We verwerken informatie van aangewezen ontvangers die u ons verstrekt voor zover nodig om de gepersonaliseerde video te bieden, hetzij als onderdeel van de inhoud of voor de transactie zelf (we delen bijvoorbeeld de naam van de ontvanger en de details van het verzoek met de’STAR’). Als u een mobiel telefoonnummer opgeeft, gebruiken we dit om sms-berichten met betrekking tot de transactie te verstrekken.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      3.	Deel neemt aan wedstrijden of promoties. We verzamelen de informatie die u verstrekt wanneer u deelneemt aan een wedstrijd of promotie op of via onze site. Sommige van uw persoonlijke gegevens kunnen openbaar worden gemaakt als u een wedstrijd wint. We gebruiken deze informatie niet voor andere doeleinden dan voor het uitvoeren en beheren van de wedstrijden of promoties, tenzij u uitdrukkelijk akkoord gaat dat we deze voor andere doeleinden mogen gebruiken of delen.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      4.	Contact opneemt met de klantenservice via de site. VIDSTAR verzamelt persoonlijke en andere informatie in verband met klantenservice. We gebruiken deze informatie om u en anderen klantenservice te bieden, te helpen bij het oplossen van problemen en om onze klantenservice en processen te evalueren en te verbeteren.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      5.	Inhoud aan de site verstrekt. Onze site kan u toestaan om inhoud aan ons in te dienen, zoals een video, recensie, foto, e-mail, tekst, sociale media-post of andere communicatie (elk een "inzending") en onze site staat STAR toe om inhoud en gegevens in te dienen ( 'STAR-inhoud'). Elke inzending, ongeacht of deze rechtstreeks of via een Social Media Platform op onze site is ingediend, en alle STAR-inhoud, is onderworpen aan de toepasselijke Algemene voorwaarden die VIDSTAR bepaalde rechten geven om deze te gebruiken. Sommige van uw persoonlijke gegevens, zoals uw naam, gebruikersnaam of waar u woont, kunnen openbaar worden weergegeven. Anderen hebben er toegang toe, kunnen het gebruiken en delen.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      6.	Contact zoekt via sociale media met de site. U kunt communiceren met onze site via een eigendom van derden, zoals sociale mediaplatforms zoals Facebook, Instagram en Twitter (gezamenlijk “sociale mediaplatforms”). U kunt bijvoorbeeld uw bestaande gebruikersnaam en inloginformatie voor sociale media gebruiken om een account op onze site te maken of inhoud “liken” of “delen” van onze site. Sommige van uw persoonlijke gegevens, zoals uw gebruikersnaam, kunnen openbaar worden weergegeven. Bovendien hebben wij en het Social Media Platform mogelijk toegang tot informatie over u en uw gebruik van onze Site en het Social Media Platform. De informatie die door een derde partij wordt verzameld, is onderworpen aan zijn privacy voorwaarden (inclusief of deze informatie met ons deelt, de soorten informatie die wordt gedeeld en uw keuzes over wat zichtbaar is voor anderen).
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      7.	De site gebruikt als STAR. Wanneer u onze site gebruikt om of als 'STAR' te worden, verzamelen wij uw naam, e-mailadres, telefoonnummer en de door u verstrekte social media-gebruikersnamen, door u voorbereide video's en andere informatie met betrekking tot onze relatie met u , zoals betalingsrecords. We gebruiken deze informatie om gepersonaliseerde video’s aan onze gebruikers aan te bieden, u met hen te verbinden, te helpen bij de betalingstransactie en onze relatie met u te beheren. De betalingen van VIDSTAR aan u worden door VIDSTAR uitgevoerd. Waarbij u akkoord gaat met ‘Self-Billing’. Waarbij u uw factuur - opgesteld door de afnemer - van een maand ontvangt op tenlaatse de 5de dag van laatsgenoemde volgende maand en waarbij de betaling gebeurt tenlaatse de 15de van laatsgenoemde volgende maand.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      8.	De site gebruikt als een VIDSTAR-partner. Wanneer u onze site gebruikt om of als 'VIDSTAR-partner' te worden, verzamelen wij uw naam, e-mailadres, telefoonnummer en andere informatie met betrekking tot onze relatie met u, inclusief betalingsinformatie. We gebruiken deze informatie om te helpen bij elke betalingstransactie in het kader van het verwijzingsprogramma en onze relatie met u te beheren. De betalingen van VIDSTAR aan u worden door VIDSTAR uitgevoerd. Waarbij u akkoord gaat met ‘Self-Billing’. Waarbij u uw factuur - opgesteld door de afnemer - van een maand ontvangt op tenlaatse de 5de dag van laatsgenoemde volgende maand en waarbij de betaling gebeurt tenlaatse de 15de van laatsgenoemde volgende maand.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      9.	Solliciteert op een vacature bij VIDSTAR. U kunt bij ons solliciteren via een site dat eigendom is van derden (bijvoorbeeld via de link 'Vacatures' op onze site) of rechtstreeks bij ons. U moet het privacybeleid van de externe partij lezen voordat u uw persoonlijke informatie verstrekt, aangezien de privacy voorwaarden kunnen verschillen van de onze. We gebruiken de informatie die we verzamelen alleen in verband met uw sollicitatie en zoals hieronder uiteengezet.
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Informatie van derden:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      We kunnen ook informatie verkrijgen, zoals contactgegevens (bijvoorbeeld naam en e-mailadres), land, IP-adres, aankoopgeschiedenis, marketingvoorkeuren, cookie-informatie, hardware- en software-informatie en andere technische informatie over u van leveranciers, zoals analyses bedrijven en een eigendom van derden (inclusief een Social Media Platform). Wij en onze leveranciers kunnen informatie verzamelen over uw bezoeken aan en activiteiten op onze site en andere websites en diensten. We kunnen die informatie combineren met andere persoonlijke informatie die we van of over u hebben verzameld. We kunnen deze informatie gebruiken om advertenties en inhoud op u en anderen te richten en om de effectiviteit van onze advertenties en activiteiten te beoordelen. We kunnen bepaalde van deze informatie delen met onze leveranciers voor soortgelijke doeleinden.    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Cookies en trackingtechnologieën
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      (a) Cookies en tracking
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      Wanneer u onze site bezoekt, kunnen we tracking- en andere technologieën gebruiken, bijvoorbeeld cookies, lokale opslag, web beacons, enbedded scripts en locatiegegevensservices om informatie over u te verzamelen. Sommige van deze technologieën kunnen een unieke identificatie voor uw apparaat naar een browser overbrengen, een cookie op uw apparaat plaatsen, code tijdelijk naar uw apparaat downloaden of andere middelen gebruiken om uw toegang of interacties bij te houden.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      We gebruiken deze technologieën om een aantal redenen, waaronder om uw voorkeuren voor toekomstige bezoeken aan onze site op te slaan, u tussen bezoeken aangemeld te houden en om u verbeterde diensten te bieden. De informatie die we verzamelen kan bevatten (maar is niet beperkt tot):
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      datum en tijd van uw bezoek aan onze site;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      gebieden die u bezoekt op onze site;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      links waarop u klikt op onze site;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      websites of advertenties die u vóór of na het bezoeken van onze site bezoekt;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      termen die u heeft ingevoerd in een zoekmachine die u naar onze site leiden;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      IP-adres, mobiele apparaat-ID of andere unieke ID en gebruiksinformatie voor het apparaat dat wordt gebruikt om toegang te krijgen tot onze Site;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      precieze locatiegegevens van uw apparaat, als u zich hebt aangemeld voor het verzamelen van die informatie (u kunt mogelijk het verzamelen van precieze locatiegegevens uitschakelen via de instellingen op het apparaat dat wordt gebruikt om toegang te krijgen tot onze Site, maar uw geschatte locatie kan blijven beschikbaar via het IP-adres of andere informatie die we verzamelen);
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      apparaat- en verbindingsinformatie, zoals browsertype en -versie, besturingssysteem en platform; en
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      of een door ons verzonden e-mailbericht is geopend en of er op een koppeling in het e-mailbericht is geklikt.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      (b) "Niet volgen" -verzoeken
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
    VIDSTAR honoreert momenteel geen "Do Not Track" verzoeken.   
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Hoe we informatie gebruiken
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      We gebruiken de informatie die we verzamelen zoals hierboven beschreven. We kunnen ook informatie over u verzamelen van u en van andere bronnen, en we kunnen die informatie combineren om onze Site te bedienen, aan te passen, te personaliseren, aan te passen en te verbeteren.
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      We kunnen uw informatie ook gebruiken:
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      om contact met u op te nemen over administratieve zaken, zoals de toepasselijke Algemene voorwaarden of ander beleid;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      om uw persoonlijke voorkeuren beter te begrijpen, zodat wij u betere services kunnen bieden;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      om verzamelde gegevens over siteverkeer en interactie samen te stellen;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      om de inhoud en advertenties aan te passen die we aan u of anderen, op onze site of elders weergeven, en om trends en statistieken te analyseren;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      advertentiemodellen maken door middel van lookalike modellering of andere onderzoeksmethoden;
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      voor interne zakelijke doeleinden, zoals het verbeteren van onze site, producten en diensten en om te voldoen aan wettelijke vereisten en onze zakelijke praktijken, zoals ons beleid inzake archivering, back-up en documentbewaring; en
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
      om andere redenen die we vrijgeven wanneer u uw informatie verstrekt, met uw toestemming en zoals beschreven in dit privacybeleid.
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Wettelijke rechten
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
    We zullen uw persoonlijke informatie vrijgeven wanneer dat nodig is om onze wettelijke rechten uit te oefenen, vast te stellen of te verdedigen. We kunnen bijvoorbeeld uw accountgegevens controleren om beschuldigingen van hacking of een schending van een van de Algemene voorwaarden te onderzoeken. We zullen uw persoonlijke informatie bekendmaken aan onze juridische en andere adviseurs, consultants en wetshandhavingsinstanties of andere overheidsinstanties.    
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Anderen beschermen
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
    We zullen uw persoonlijke informatie aan derden bekendmaken wanneer we van mening zijn dat de openbaarmaking noodzakelijk is om de rechten, eigendommen of veiligheid van een ander te beschermen. We geven bijvoorbeeld persoonlijke informatie met betrekking tot een Site-account vrij als we te goeder trouw geloven dat een Site-account wordt gebruikt op manieren die schadelijk zijn voor een ander of in strijd met de toepasselijke Algemene voorwaarden.    
    </span>
    <br />
    <br />
    <br />
    <span className={styles.Subtitle}>
      Verkoop van ons bedrijf of activa
    </span>
    <br />
    <br />
    <span className={styles.InfoText}>
    Bij het evalueren of uitvoeren van een verkoop van activa, fusie, acquisitie, reorganisatie, faillissement of andere transactie kunnen we uw persoonlijke informatie en communicatie zonder uw verdere toestemming bekendmaken, overdragen of toewijzen.    
    </span>
    <br />
    <br /> 
    <br /> 
    <span className={styles.Subtitle}>
      Kinderen en ouders
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    Onze site is bedoeld voor een algemeen publiek. We verzamelen niet bewust persoonlijke informatie van kinderen (kinderen onder de relevante leeftijdsgrens in het toepasselijke rechtsgebied) zonder de toestemming van een ouder of wettelijke voogd; als we ontdekken dat we dit hebben gedaan, zullen we de informatie onmiddellijk verwijderen. Als u een ouder of wettelijke voogd bent en gelooft dat we de persoonlijke gegevens van hun kind hebben verzameld of gebruikt, neem dan contact met ons op via de contactgegevens in de sectie Contact aan het einde van dit beleid.    
    </span>
    <br />
    <br /> 
    <br /> 
    <span className={styles.Subtitle}>
      Jouw Keuzes
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    (a) Uw keuzes over persoonlijke informatie die u aan VIDSTAR verstrekt
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    Als u VIDSTAR geen persoonlijke informatie wilt verstrekken die wij van u vragen, kunt u ervoor kiezen om deze niet te verstrekken. Houd er rekening mee dat als u ervoor kiest om ons geen persoonlijke informatie te verstrekken, we u mogelijk niet altijd het relevante VIDSTAR-product of de service kunnen leveren waar u om vraagt. Zonder een e-mailadres op te geven, kunt u bijvoorbeeld geen Site-account maken.
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    Om het ontvangen van promotionele e-mailcommunicaties van VIDSTAR te stoppen, klikt u op de link "afmelden" in de voettekst van de promotie-e-mail. Houd er rekening mee dat we mogelijk nog steeds contact met u opnemen over de status van uw account.
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    (b) Uw keuzes over persoonlijke informatie die door derden aan ons is verstrekt
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    U kunt mogelijk bepalen hoe sommige van onze leveranciers uw persoonlijke gegevens verzamelen en delen door uw voorkeuren te wijzigen of u af te melden voor rechtstreekse verzameling met die derde partijen. Voordat u persoonlijke informatie aan derden verstrekt, dient u het privacybeleid te controleren.
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
    VIDSTAR maakt gebruik van Google Analytics.
    </span> 
    <span className={styles.InfoText}>
    <a className={styles.TextLink} href="https://support.google.com/ads/answer/2662922" target="_blank"> Klik hier </a>
    </span> 
    <span className={styles.InfoText}>
    voor informatie van Google over opt-out en 
    </span>
    <span className={styles.InfoText}> 
    <a className={styles.TextLink} href="https://tools.google.com/dlpage/gaoptout" target="_blank"> klik hier </a>
    </span> 
    <span className={styles.InfoText}>
    om de opt-outbrowser Google Analytics van Google te downloaden.
    </span>
    <br />
    <br /> 
    <br /> 
    <span className={styles.Subtitle}>
      Toegang tot persoonlijke informatie en bewaartermijn
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (a) Toegang tot uw account    
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Als u om welke reden dan ook toegang wilt krijgen tot de over u verzamelde persoonlijke informatie, deze wilt bekijken, corrigeren of bijwerken, ga dan eenvoudig naar uw Site-account via vidstar.be of de mobiele applicatie en bewerk uw persoonlijke informatie. Als je je account wilt deactiveren, stuur dan een e-mail naar help@vidstar.be; STAR’s kunnen hun account ook deactiveren via hun accountinstellingen.    
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Als u om welke reden dan ook bezorgd bent over de manier waarop wij uw persoonlijke informatie gebruiken, de persoonlijke informatie die u ons hebt verstrekt wilt corrigeren of wilt verzoeken dat wij dergelijke persoonlijke informatie verwijderen, kunt u ons uw verzoek sturen.    
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (b) Bewaartermijn    
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Wanneer u ons vraagt om uw persoonlijke gegevens te verwijderen, zullen we commercieel redelijke stappen ondernemen om deze uit onze actieve databases te verwijderen, maar zullen we originele informatie consistent houden met onze zakelijke praktijken (bijvoorbeeld voor geschillenbeslechting, handhaving van overeenkomsten, naleving van wettelijke vereisten , archivering, back-up en documentbehoud). In elk geval zullen we uw persoonlijke gegevens niet langer bewaren of gebruiken dan nodig is voor de doeleinden die in dit Privacybeleid worden uiteengezet.    
    </span> 
    <br />
    <br /> 
    <br /> 
    <span className={styles.Subtitle}>
      Dataveiligheid
    </span>
    <br />
    <br /> 
    <span className={styles.InfoText}>
      VIDSTAR neemt commercieel redelijke beveiligingsmaatregelen om de aan ons verstrekte informatie te beschermen. Geen enkele verzendingsmethode via internet of elektronische opslag is 100% veilig en u gebruikt onze site en verzendt informatie naar ons op eigen risico. Als u vragen hebt over de beveiliging op onze site, neemt u contact met ons op via de contactgegevens in de sectie Contact aan het einde van dit beleid.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      VIDSTAR is de gegevensbeheerder voor de doeleinden van de Algemene Verordening Gegevensbescherming (EU) 2016/679 (“GDPR”); en "persoonlijke informatie" zoals gebruikt in dit Privacybeleid zijn "persoonlijke gegevens", zoals gedefinieerd in artikel 4 (1) van de AVG; als u via onze site solliciteert naar een baan bij een van onze partners, zoals bij VIDSTAR, is de partner ook een gegevensbeheerder. Meer informatie over onze filialen en hoe u contact met hen kunt opnemen via de contactgegevens hier.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Wij verwerken uw informatie voor de hieronder uiteengezette doeleinden.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (a) Wettelijke basis voor het verwerken van informatie
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      We verwerken uw gegevens waar we daarvoor op wettelijke gronden kunnen vertrouwen.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (b) Prestaties van de Services
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      We verwerken uw informatie voor de prestaties van onze site, om onze producten en diensten te leveren of te ondersteunen, of om een andere reden die u aanvraagt of inschakelt. Dit omvat bijvoorbeeld het gebruik van uw informatie om:
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      uw site-account beheer;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      het uitvoeren en beheren van wedstrijden of promoties die u hebt ingevoerd;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      ondersteuning voor aankopen die u doet, inclusief, maar niet beperkt tot, het verwerken van betalingen en bestellingen;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      ondersteuning Sitefunctionaliteit;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      onze site onderhouden in overeenstemming met dit privacybeleid en de toepasselijke Algemene voorwaarden; en klantenservice bieden.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (c) Toestemming
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
     Wij verwerken uw gegevens op basis van uw toestemming. Deze verwerking omvat bijvoorbeeld het verstrekken van:
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      nieuwsbrieven, directe e-mails en enquêtes over onze site; en
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      bepaalde andere marketingfuncties.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Waar we op uw toestemming vertrouwen om uw persoonlijke informatie te verwerken, hebt u het recht om uw toestemming voor verder gebruik van uw persoonlijke informatie op elk gewenst moment in te trekken. U kunt de methoden gebruiken die hierboven zijn beschreven in het gedeelte Toegang tot persoonlijke informatie en bewaartermijn om uw marketingvoorkeuren bij te werken.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (d) Legitieme belangen
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Wij verwerken uw gegevens wanneer wij hier een legitiem belang bij hebben. Dit omvat bijvoorbeeld het verwerken van uw informatie om:
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      u de gevraagde klantenservice of technische ondersteuning te bieden;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      debuggen en verbeteren van onze huidige en toekomstige site aanpassingen;
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      u voorzien van bepaalde inhoud, uw site-ervaring bij ons personaliseren en contact met u opnemen in overeenstemming met uw toepasselijke marketingvoorkeuren; en
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      juridische claims instellen, uitoefenen of verdedigen of in verband met een rechtbank of jurisdictie.
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      (e) Wettelijke verplichting
    </span> 
    <br />
    <br /> 
    <span className={styles.InfoText}>
      Wij verwerken uw informatie om te voldoen aan elke wettelijke verplichting waaraan wij zijn onderworpen.
    </span> 
    <br />
    <br />   
  </div>
  );
}

export default Privacy;
