import React from "react";
import { Layout } from "antd";
import styles from "./HowPage.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Footer from "../../Components/Footer/Footer";
import How from "../../Components/How/How";


function HowPage() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
         <How/>
      </section>
      <Footer />
    </Layout>
  );
}

export default HowPage;