import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Star from "./Pages/Star/Star";
import FAQPage from "./Pages/FAQ/FAQPage";
import VideoPage from "./Pages/Video/VideoPage";
import IntroVideoAppPage from "./Pages/IntroVideoApp/IntroVideoAppPage";
import HowPage from "./Pages/How/HowPage";
import PrivacyPage from "./Pages/Privacy/PrivacyPage";
import SecretPage from "./Pages/Secret/SecretPage";
import TermsPage from "./Pages/Terms/TermsPage";
import OrderPage from "./Pages/Order/OrderPage";
import HelpPage from "./Pages/Help/HelpPage";
import EnrollPage from "./Pages/Enroll/EnrollPage";
import PaidPage from "./Pages/Paid/PaidPage";
import Payment from "./Pages/Payment/Payment";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop"

function App() {

  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route path="/Checkout">
          <Payment />
        </Route>
        <Route path="/Order/:star">
          <OrderPage />
        </Route>
        <Route path="/Star/:star">
          <Star />
        </Route>
        <Route path="/FAQ">
          <FAQPage />
        </Route>
        <Route path="/IntroVideoApp">
          <IntroVideoAppPage />
        </Route>
        <Route
          path='/V/:orderId'
          render={props => <VideoPage {...props} />}
        />
        <Route path="/How">
          <HowPage />
        </Route>
        <Route path="/Privacy">
          <PrivacyPage/>
        </Route>
        <Route path="/Terms">
          <TermsPage />
        </Route>
        <Route path="/Help">
          <HelpPage />
        </Route>
        <Route path="/Enroll">
          <EnrollPage />
        </Route>
        <Route path="/Paid">
          <PaidPage />
        </Route>
        <Route path="/Home">
          <Home />
        </Route>
        {/* If none of the previous routes render anything,
          this route acts as a fallback.

          Important: A route with path="/" will *always* match
          the URL because all URLs begin with a /. So that's
          why we put this one last of all */}
        <Route path="/">
          <SecretPage/>
        </Route>
      </Switch>
      </Router>
  );
}

export default App;
