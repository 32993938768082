import React from "react";
import styles from "./IntroVideoApp.module.css";
import IntroVideoAppVideo from "../IntroVideoAppVideo/IntroVideoAppVideo";

function IntroVideoApp(props) {
  return (
     <div className={styles.Rows}>
        <div className={styles.ColumnsReview}>    
          <br />
            <div className={(styles.Columns, styles.InfoStars)}>
              <p className={styles.StarName}>Introductie Video App</p>
            </div>
        </div>
        <div className={styles.Columns}>
          <IntroVideoAppVideo  
            Video={props.src}
            Poster={props.StarPoster} 
          />
        </div>
     </div>
  );
}

export default IntroVideoApp;