import React from "react";
import { Layout } from "antd";
import styles from "./Home.module.css";
import TopNavigation from "../../Components/TopNavigation/TopNavigation";
import Caroussel from "../../Components/Caroussel/Caroussel";
import CardRow from "../../Components/CardRow/CardRow";
import WhatIs from "../../Components/WhatIs/whatIs";
import Footer from "../../Components/Footer/Footer";

function Home() {
  return (
    <Layout>
      <TopNavigation />
      <section className={styles.middle}>
        <Caroussel className={styles.Caroussel} />
        <CardRow Title="Meest bekeken" />
        <WhatIs />
        <CardRow Title="Nieuw" />
      </section>
      <Footer />
    </Layout>
  );
}

export default Home;
